import { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import { motion } from "framer-motion";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import isMobileViewport from "@marathon/web/hooks/isMobileViewport";
import { Button } from "@marathon/web/components/Button";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { Customer, CustomerAddressData } from "@marathon/common/entities/Customer";
import { SecondaryAddressInput } from "@marathon/client-side/repositories/SecondaryAddressRepository";
import { Loading } from "@marathon/web/components/Loading";
import regionMapImg from "images/region-map.jpg";
import { FieldsetWrapper } from "./CommonWrappers";
import Map from "./Map";
import { GoogleMapsLoader } from "@marathon/web/components/GoogleMapsLoader";
import { SecondaryAddress } from "@marathon/common/entities/SecondaryAddress";
import GooglePlaces, { GoogleAddressValue } from "@marathon/web/utilities/GooglePlaces";
import { logErrorAndShowAlert } from "@marathon/web/helpers/errorHandlingHelper";

interface Option {
    value: string;
    label: string;
}

const defaultAddressValues = Customer.getDefaultAddress();

export interface AddressFormProps {
    returningCustomer?: Customer,
    address: CustomerAddressData | SecondaryAddressInput,
    onAddressAutocompleteChange: (selected: { value: GoogleAddressValue } | null) => Promise<void>,
    handleChange: (address: CustomerAddressData | SecondaryAddress | SecondaryAddressInput) => void,
    onNext: Function,
    isLoading: boolean,
    isGeocoding: boolean,
    isSecondaryAddress?: boolean,
    renderReturningAddressButtons?: () => JSX.Element
}

const AddressForm = ({
    returningCustomer,
    address,
    onAddressAutocompleteChange,
    handleChange,
    onNext,
    isLoading,
    isGeocoding,
    isSecondaryAddress,
    renderReturningAddressButtons
}: AddressFormProps) => {
    const isMobile = isMobileViewport();
    const classes = useStyles();

    const [isLoadingDefaultValue, setIsLoadingDefaultValue] = useState(false);
    const [defaultValue, setDefaultValue] = useState<Option | null>(null);

    const isFromSameHub = address.drive_time?.hub_id === returningCustomer?.address.drive_time?.hub_id;

    useEffect(() => {
        const fetchAddress = async (placeId: string) => {
            setIsLoadingDefaultValue(true);
            try {
                setDefaultValue(await GooglePlaces.getGoogleAutocompleteOption(placeId));
            } catch (error) {
                logErrorAndShowAlert(error);
            }
            setIsLoadingDefaultValue(false);
        };

        if (address.place_id)
            fetchAddress(address.place_id);

        // HACK: we only want this to be triggered once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderContinueButton = () => {
        const isWaitlistAction = address.address1 && !address.drive_time;
        const buttonText = isWaitlistAction ? "Join waitlist" : "Continue";
        return (
            <Button
                disabled={!address.place_id}
                onClick={onNext}
                variant={isWaitlistAction ? "outlined" : "contained"}
                className={address.drive_time ? classes.button : classes.buttonSecondary}
                disableElevation
                showSpinner={isLoading || isGeocoding}
            >
                {buttonText}
            </Button>
        );
    };

    const mapClassName = address.drive_time
        ? "within-service-area"
        : (address.address1 ? "outside-service-area" : "not-geolocated");

    if (isLoadingDefaultValue) return <Loading />;

    return (
        <Box mt={2} display="flex" flexDirection={{ xs: "column", sm: "row" }} alignItems="center">
            <Box width={{ xs: "100%", sm: "100%", md: "100%", lg: "600px" }}>
                <FieldsetWrapper>
                    <Box className={classes.textFieldWithoutMargin}>
                        <GoogleMapsLoader>
                            <GooglePlacesAutocomplete
                                selectProps={{
                                    placeholder: "Address",
                                    onChange: onAddressAutocompleteChange,
                                    defaultValue,
                                    isClearable: true,
                                    noOptionsMessage: () => "Please enter your address",
                                    styles: {
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        control: (base: any, state: { isFocused: any; }) => ({
                                            ...base,
                                            "&:hover": {
                                                borderColor: state.isFocused ? "#0066FF" : "#000",
                                                borderWidth: state.isFocused ? 2 : 1
                                            },
                                            border: "1px solid #aaa",
                                            boxShadow: "none",
                                            color: "#000",
                                            height: 56,
                                            textAlign: "left"
                                        }),
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        indicatorSeparator: (styles: any) => ({
                                            ...styles,
                                            display: "none"
                                        }),
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        dropdownIndicator: (styles: any) => ({
                                            ...styles,
                                            display: "none"
                                        }),
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        menu: (provided: any) => ({
                                            ...provided,
                                            textAlign: "left",
                                            zIndex: 50
                                        })
                                    }
                                }}
                                autocompletionRequest={{
                                    componentRestrictions: {
                                        country: "us"
                                    },
                                    types: ["address"]
                                }}
                            />
                        </GoogleMapsLoader>
                    </Box>
                    {isSecondaryAddress && (
                        <TextField
                            autoComplete="off"
                            variant="outlined"
                            className={classes.textField}
                            color="primary"
                            size="medium"
                            label="Alias"
                            value={(address as SecondaryAddressInput).alias ?? ""}
                            onChange={e => handleChange({ ...defaultAddressValues, ...address, alias: e.target.value })}
                        />
                    )}
                    <TextField
                        autoComplete="off"
                        variant="outlined"
                        className={classes.textField}
                        color="primary"
                        size="medium"
                        label="Apt / Unit #"
                        value={address.address2 ?? ""}
                        onChange={e => handleChange({ ...defaultAddressValues, ...address, address2: e.target.value })}
                    />
                    <TextField
                        autoComplete="off"
                        variant="outlined"
                        className={classes.textField}
                        color="primary"
                        size="medium"
                        label="Parking Notes"
                        value={address.parking_notes ?? ""}
                        onChange={e => handleChange({ ...defaultAddressValues, ...address, parking_notes: e.target.value })}
                    />
                    {!isMobile &&
                        <>
                            {renderReturningAddressButtons ? renderReturningAddressButtons() : renderContinueButton()}
                        </>}
                </FieldsetWrapper>
            </Box>
            <Box width={{ xs: "100%", sm: "100%", md: "100%", lg: "600px" }} mt={isMobile ? 3 : 0} pl={{ sm: 2, md: 2, lg: 6 }}>
                {!address.address1 &&
                    <img src={regionMapImg} className={`lead-address-map ${mapClassName}`} alt="Barkbus - Region map" style={{ display: "block", maxHeight: isMobile ? undefined : isSecondaryAddress ? 338 : 270 }} />}
                {address && address.address1 &&
                    <motion.div
                        className={`lead-address-map ${mapClassName}`}
                        initial={{ x: 100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        {address.drive_time && (!isSecondaryAddress || isFromSameHub) &&
                            <div className="service-area-result-banner success">
                                <div className="service-area-ok-icon">👍</div>
                                Awesome, we service your address!
                            </div>}
                        {!address.drive_time &&
                            <div className="service-area-result-banner failure">
                                Sorry, you are outside our service area
                            </div>}
                        {address.drive_time && isSecondaryAddress && !isFromSameHub &&
                            <div className="service-area-result-banner failure">
                                Sorry, you are outside your primary address service area
                            </div>}

                        <Map
                            coords={
                                address.lat && address.lng
                                    ? { lat: address.lat, lng: address.lng }
                                    : null
                            }
                            markerLabel={address.address1 ?? ""}
                            height={isSecondaryAddress ? 296 : 228}
                        />
                    </motion.div>}
                {isMobile &&
                    <>
                        {renderReturningAddressButtons ? renderReturningAddressButtons() : renderContinueButton()}
                    </>}
            </Box>
        </Box>
    );
};

export default AddressForm;