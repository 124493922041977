import { DiscountType } from "./Discount";

interface UsedDiscountData {
    code: string,
    applied_at: Date,
    type: DiscountType,
    value: number
}

interface UsedDiscount extends UsedDiscountData {
    id: string,
    customerId: string
}

class UsedDiscount {
    constructor(id: string, customerId: string, data: UsedDiscountData) {
        this.id = id;
        this.customerId = customerId;
        Object.assign(this, data);
    }
    toData(): UsedDiscountData {
        const { id, customerId, ...data } = this;
        return data;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromApi(serialized: any) {
        const { id, customerId, ...data } = serialized;
        return new UsedDiscount(id, customerId, data);
    }
}

export { UsedDiscount };
export type { UsedDiscountData };