import { useLocation, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Swal from "sweetalert2";
import { Loading } from "@marathon/web/components/Loading";
import { Button } from "@marathon/web/components/Button";
import { logErrorAndShowTitleOnlyAlert } from "@marathon/web/helpers/errorHandlingHelper";
import { OnlineBookingHeader } from "components/onlineBooking/OnlineBookingHeader";
import { OnlineBookingContentWrapper } from "components/onlineBooking/OnlineBookingContentWrapper";
import { ContentWrapper } from "components/onlineBooking/shared/CommonWrappers";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import steps from "@marathon/common/utilities/OnlineBookingSteps";

interface RouteParams {
    year: string;
}

export default function Unleashed() {
    const routeParams = useParams<RouteParams>();
    const query = new URLSearchParams(useLocation().search);
    const videoUrl = decodeURI(query.get("video_url") ?? "");
    const classes = useStyles();

    if (!routeParams.year || !videoUrl) {
        return <Loading />;
    }

    return (
        <>
            <OnlineBookingHeader logoUrl={`/online-booking/${steps.returning.welcome}`} />
            <OnlineBookingContentWrapper>
                <ContentWrapper >
                    <video controls width="100%" style={{ maxHeight: "60svh", objectFit: "contain" }}>
                        <source src={`${videoUrl}#t=0.001`} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <Box display="flex" flexDirection="column">
                        <Button
                            className={classes.button}
                            variant="contained"
                            onClick={async () => {
                                try {
                                    const response = await fetch(videoUrl);
                                    const blob = await response.blob();
                                    const filesArray = [
                                        new File([blob], `Barkbus_unleashed_${routeParams.year}.mp4`, {
                                            type: "video/mp4",
                                            lastModified: new Date().getTime(),
                                        }),
                                    ];
                                    const shareData = {
                                        title: `Barkbus unleashed ${routeParams.year}`,
                                        files: filesArray,
                                    };

                                    if (navigator.canShare && navigator.canShare(shareData)) {
                                        await navigator.share(shareData);
                                    }
                                    else {
                                        Swal.fire("Your browser does not support sharing files");
                                    }
                                } catch (error) {
                                    if (error instanceof DOMException && error.name === "AbortError") {
                                        return;
                                    }
                                    logErrorAndShowTitleOnlyAlert(error, "Error sharing video");
                                }
                            }}
                            iconOnLeftEdge
                            iconName="ios_share">
                            Share
                        </Button>
                        <Button
                            className={classes.buttonSecondary}
                            variant="outlined"
                            onClick={() => {
                                const link = document.createElement("a");
                                link.href = videoUrl;
                                link.download = `Barkbus_unleashed_${routeParams.year}.mp4`;
                                link.target = "_blank";
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            }}
                            iconOnLeftEdge
                            iconName="download">
                            Download
                        </Button>
                    </Box>
                </ContentWrapper>
            </OnlineBookingContentWrapper>
        </>
    );
}