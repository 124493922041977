import { DocResult, IFirestoreService, SearchParams } from "@marathon/client-side/repositories/IFirestoreService";
import { deleteFieldInternal, UpdateDataInternal } from "@marathon/common/utilities/TypeUtils";
import { doc, getDoc, query, where, getDocs, updateDoc, UpdateData, deleteDoc, orderBy, onSnapshot, deleteField, limit, startAfter, documentId, QueryDocumentSnapshot, DocumentSnapshot, CollectionReference, collection, collectionGroup, Query, getFirestore, setDoc, FieldPath } from "firebase/firestore";
import { DocumentData } from "firebase/firestore";
import { MAX_IN_PARAMETERS_LENGTH } from "@marathon/common/utilities/Firestore";
import { TimestampConverter } from "./TimestampConverter";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";

const firestore = getFirestore();

const createCollection = <T extends DocumentData = DocumentData>(path: string) => {
    if (!path)
        throw new Error("Collection path is not defined");

    const result = collection(firestore, path) as CollectionReference<T>;
    return result.withConverter<T, DocumentData>(new TimestampConverter<T>());
};

const createCollectionGroup = <T extends DocumentData = DocumentData>(collectionId: string) => {
    if (!collectionId)
        throw new Error("Collection id is not defined");

    const result = collectionGroup(firestore, collectionId) as Query<T>;
    return result.withConverter<T, DocumentData>(new TimestampConverter<T>());
};

const getDocResult = <T>(doc: QueryDocumentSnapshot<T, DocumentData> | DocumentSnapshot<T, DocumentData>): DocResult<T> => {
    return {
        id: doc.id,
        data: doc.data() as T,
        parentId: doc.ref.parent.parent?.id,
        baseCollection: getBaseCollectionFromDoc(doc),
        docSnapshot: doc
    };
};

const getBaseCollectionFromDoc = <T>(doc: QueryDocumentSnapshot<T, DocumentData> | DocumentSnapshot<T, DocumentData>) => {
    const path = doc.ref.parent.parent?.path;
    if (!path) return undefined;
    return path.split("/")[0];
};

export class FirestoreService<T extends DocumentData> implements IFirestoreService<T> {
    collectionPath: CollectionPaths | undefined;
    parentCollectionPath: CollectionPaths | undefined;

    private getCollection(parentId?: string) {
        if (this.collectionPath === undefined)
            throw new Error("Collection path is not defined");

        if (parentId !== undefined && this.parentCollectionPath === undefined)
            throw new Error("Parent collection path is not defined");

        return (
            parentId
                ? createCollection<T>(`${this.parentCollectionPath}/${parentId}/${this.collectionPath}`)
                : createCollection<T>(this.collectionPath)
        );
    }

    private getCollectionGroup() {
        if (this.collectionPath === undefined)
            throw new Error("Collection path is not defined");

        return createCollectionGroup<T>(this.collectionPath);
    }

    private async searchInBatch(field: string | FieldPath, values: string[], useCollectionGroup = false): Promise<DocResult<T>[]> {
        const valuesCopy = [...values];
        const batches: Promise<DocResult<T>[]>[] = [];
        while (valuesCopy.length) {
            const batch = valuesCopy.splice(0, MAX_IN_PARAMETERS_LENGTH);
            const collectionRef = useCollectionGroup ? this.getCollectionGroup() : this.getCollection();
            const customQuery = query(collectionRef, where(field, "in", batch));

            const batchPromise = getDocs(customQuery)
                .then(results => results.docs.map(doc => getDocResult(doc)))
                .catch(error => {
                    console.error("Batch fetch error", error);
                    throw error;
                });

            batches.push(batchPromise);
        }

        const batchResults = await Promise.all(batches);
        return batchResults.flat();
    }

    async searchInBatchByIds(ids: string[]): Promise<DocResult<T>[]> {
        return this.searchInBatch(documentId(), ids);
    }

    async searchInBatchByField(field: string, values: string[], useCollectionGroup = false): Promise<DocResult<T>[]> {
        return this.searchInBatch(field, values, useCollectionGroup);
    }

    async getById(id: string, parentId?: string): Promise<DocResult<T> | null> {
        const collectionRef = this.getCollection(parentId);
        const docRef = doc(collectionRef, id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return getDocResult(docSnap);
        } else {
            return null;
        }
    }

    async search(searchParams?: SearchParams<T>, parentId?: string): Promise<DocResult<T>[]> {
        const collectionRef = this.getCollection(parentId);
        let queryRef: Query<T> = query(collectionRef);

        if (searchParams) {
            const { filters, orders, limit: limitNumber, afterDocument } = searchParams;

            if (filters) {
                filters.forEach(f => {
                    queryRef = query(queryRef, where(f.field as string, f.operator, f.value));
                });
            }

            if (limitNumber) {
                queryRef = query(queryRef, limit(limitNumber));
            }

            if (orders) {
                orders.forEach(o => {
                    queryRef = query(queryRef, orderBy(o.field as string, o.direction ?? "asc"));
                });
            }

            if (afterDocument) {
                queryRef = query(queryRef, startAfter(afterDocument));
            }
        }

        const snapshot = await getDocs(queryRef);
        if (snapshot.empty) {
            return [];
        }
        return snapshot.docs.map(doc => getDocResult(doc));
    }

    async searchAcross(searchParams: SearchParams<T>): Promise<DocResult<T>[]> {
        const collectionRef = this.getCollectionGroup();
        const { filters, orders, limit: limitNumber, afterDocument } = searchParams;
        let queryRef: Query<T> = query(collectionRef);

        if (filters) {
            filters.forEach(f => {
                queryRef = query(queryRef, where(f.field as string, f.operator, f.value));
            });
        }

        if (limitNumber) {
            queryRef = query(queryRef, limit(limitNumber));
        }

        if (orders) {
            orders.forEach(o => {
                queryRef = query(queryRef, orderBy(o.field as string, o.direction ?? "asc"));
            });
        }

        if (afterDocument) {
            queryRef = query(queryRef, startAfter(afterDocument));
        }

        const snapshot = await getDocs(queryRef);

        if (snapshot.empty) {
            return [];
        }

        return snapshot.docs.map(doc => getDocResult(doc));
    }

    async create(data: T, parentId?: string, customId?: string): Promise<string> {
        const collectionRef = this.getCollection(parentId);
        const docRef = customId ? doc(collectionRef, customId) : doc(collectionRef);
        await setDoc(docRef, data);
        return docRef.id;
    }

    async update(id: string, data: UpdateDataInternal<T>, parentId?: string): Promise<void> {
        const collectionRef = this.getCollection(parentId);
        const docRef = doc(collectionRef, id);
        const dataToUpdate = data as DocumentData;
        checkPropertiesForDeletion(dataToUpdate);
        await updateDoc(docRef, dataToUpdate as UpdateData<T>);
    }

    async hardDelete(id: string, parentId?: string): Promise<void> {
        const collectionRef = this.getCollection(parentId);
        await deleteDoc(doc(collectionRef, id));
    }

    onQuerySnapshot(
        queryParams: SearchParams<T>,
        callback: (data: DocResult<T>[]) => void,
        parentId?: string,
        onFinish?: () => void
    ) {
        const collectionRef = this.getCollection(parentId);
        const { filters, orders, limit: limitCount } = queryParams;
        let queryRef: Query<T> = query(collectionRef);

        if (filters) {
            filters.forEach(f => {
                queryRef = query(queryRef, where(f.field as string, f.operator, f.value));
            });
        }

        if (orders) {
            orders.forEach(o => {
                queryRef = query(queryRef, orderBy(o.field as string, o.direction ?? "asc"));
            });
        }

        if (limitCount) {
            queryRef = query(queryRef, limit(limitCount));
        }

        return onSnapshot(queryRef, snapshot => {
            if (snapshot.empty) {
                callback([]);
                return;
            }
            const docs: DocResult<T>[] = snapshot.docs.map(doc => getDocResult(doc));
            callback(docs);
            if (onFinish) onFinish();
        });
    }

    onDocumentSnapshot(
        id: string,
        callback: (data: DocResult<T>) => void,
        parentId?: string
    ) {
        const collectionRef = this.getCollection(parentId);
        const docRef = doc(collectionRef, id);
        return onSnapshot(docRef, doc => {
            if (!doc.exists()) {
                return null;
            }
            callback(getDocResult(doc));
            return;
        });
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const checkPropertiesForDeletion = (data: any, depth = 0, maxDepth = 10) => {
    if (depth > maxDepth) {
        throw new Error("Maximum depth for deletion check exceeded");
    }
    Object.entries(data).forEach(([key, value]) => {
        if (value === deleteFieldInternal) {
            data[key] = deleteField();
        } else if (Array.isArray(value)) {
            value.forEach(item => {
                if (typeof item === "object" && item !== null) {
                    checkPropertiesForDeletion(item, depth + 1, maxDepth);
                }
            });
        } else if (typeof value === "object" && value !== null) {
            checkPropertiesForDeletion(value, depth + 1, maxDepth);
        }
    });
};