import { LoadScriptNext } from "@react-google-maps/api";

const libraries: ("places" | "drawing" | "geometry" | "visualization")[] = ["places", "geometry", "drawing"];

export const GoogleMapsLoader = ({ children }: { children: React.ReactElement }) => {
    return (
        <LoadScriptNext googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ""} libraries={libraries}>
            {children}
        </LoadScriptNext>
    );
};