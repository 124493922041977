//TODO: When needed, replace the hardcoded values with a database collection.
export default class Team {

    id: string;
    name: string;

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }

    static getTeams() {
        return [
            Team.getCentralOpsTeam(),
            Team.getOnlineBookingReviewTeam(),
            Team.getAbandonedReturningTeam(),
            Team.getReschedulesTeam(),
            Team.getPaymentsTeam(),
            Team.getTrustAndSafetyTeam()
        ];
    }
    static getCentralOpsTeam() {
        return new Team("3", "Central Ops");
    }
    static getOnlineBookingReviewTeam() {
        return new Team("4", "Online Booking Review");
    }
    static getAbandonedReturningTeam() {
        return new Team("5", "Abandoned Returning");
    }
    static getReschedulesTeam() {
        return new Team("6", "Reschedules");
    }
    static getPaymentsTeam() {
        return new Team("7", "Payments");
    }
    static getTrustAndSafetyTeam() {
        return new Team("8", "Trust & Safety");
    }
}