import { useEffect, useState, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import steps from "@marathon/common/utilities/OnlineBookingSteps";
import LeadExisting from "./LeadExisting";
import LeadStepGetStarted from "./LeadStepGetStarted";
import Swal from "sweetalert2";
import { CustomerRepository, InitialLeadData } from "@marathon/client-side/repositories/CustomerRepository";
import CallableFunctions from "@marathon/client-side/utilities/CallableFunctions";
import { normalizeEmail } from "@marathon/common/helpers/normalizationHelper";
import { useOnlineBookingContext } from "../OnlineBookingContext";
import { authentication } from "@marathon/web/firebaseApp";
import mixpanel from "mixpanel-browser";
import { ReferralActivityRepository } from "@marathon/client-side/repositories/ReferralActivityRepository";
import { ReferralEventType, ReferralOrigin } from "@marathon/common/entities/ReferralActivity";
import { OnlineBookingHeader } from "../OnlineBookingHeader";
import { EmailAuthProvider, UserCredential, isSignInWithEmailLink, linkWithCredential, signInWithEmailLink } from "firebase/auth";
import OnlineBookingLeadInnerContainer from "./OnlineBookingLeadInnerContainer";
import { Customer, CustomerType, LeadOrigin, LeadOutcome } from "@marathon/common/entities/Customer";
import { Pet } from "@marathon/common/entities/Pet";
import { DiscountCode } from "@marathon/common/entities/DiscountCode";
import { DiscountCodeRepository } from "@marathon/client-side/repositories/DiscountCodeRepository";
import isGroombuggyDomain from "@marathon/web/hooks/isGroombuggyDomain";

interface OnlineBookingRouteParams {
  stepNumber?: string;
  substepNumber?: string;
}

export default function OnlineBookingLeadContainer() {
  const routeParams = useParams<OnlineBookingRouteParams>();
  const { search } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);

  const [stepNumber, setStepNumber] = useState(steps.newLead.getStarted);
  const [substepNumber, setSubstepNumber] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [pets, setPets] = useState<Pet[]>([]);
  const [selectedServices, setSelectedServices] = useState<{ petId: string, serviceId: string }[]>([]);

  const [existingCustomerPhoneNumber, setExistingCustomerPhoneNumber] = useState("");
  const [getStartedFormError, setGetStartedFormError] = useState("");
  const [inviterCustomerName, setInviterCustomerName] = useState("");
  const [isLoadingDiscount, setIsLoadingDiscount] = useState(false);
  const [invitationCode, setInvitationCode] = useState(query.get("invitation"));
  const [discountCodeParam, setDiscountCodeParam] = useState(query.get("discountCode"));
  const [discountCode, setDiscountCode] = useState<DiscountCode>();

  const { errorLoadingContextData, onSignInAnonymously, isSignedIn, groomers, utmParams } = useOnlineBookingContext();

  const leadOriginToUse = isGroombuggyDomain() ? LeadOrigin.groombuggyWebsite : LeadOrigin.onlineBooking;

  const goToStep = useCallback((step: number, substep?: number, shouldReplace?: boolean, shouldRedirectToReturningFlow?: boolean) => {
    const basePath = shouldRedirectToReturningFlow ? "/online-booking" : "/online-booking-signup";
    const newUrl = substep
      ? `${basePath}/${step}/${substep}`
      : `${basePath}/${step}`;

    if (shouldReplace) {
      history.replace(newUrl);
    } else {
      history.push(newUrl);
    }
  }, [history]);

  const handleFirstStep = async (getStartedData: InitialLeadData) => {
    setIsLoading(true);

    if (!authentication.currentUser) {
      Sentry.setUser({
        username: getStartedData.phoneNumber,
        email: getStartedData.email
      });
    }

    const customerPublicInfo = await CallableFunctions.current.public.getCustomerPublicInfo({ phone: getStartedData.phoneNumber });
    if (customerPublicInfo && customerPublicInfo.type !== CustomerType.lead) {
      setIsLoading(false);
      setExistingCustomerPhoneNumber(getStartedData.phoneNumber);
      goToStep(steps.newLead.getStarted, steps.newLead.getStartedSubsteps.foundExisting);
      return;
    }

    const customerOrLeadWithSameEmail = await CallableFunctions.current.public.getCustomerPublicInfo({ email: getStartedData.email });
    if (customerOrLeadWithSameEmail && (!customerPublicInfo || customerPublicInfo.id !== customerOrLeadWithSameEmail.id)) {
      setGetStartedFormError(Customer.errorMessages.emailAlreadyInUse);
      setIsLoading(false);
      return;
    }

    const matchingGroomer = groomers.find(x => x.phone === getStartedData.phoneNumber);
    if (matchingGroomer) {
      setGetStartedFormError(Customer.errorMessages.phoneNumberNotValid);
      setIsLoading(false);
      return;
    }

    try {
      const userCredential = await onSignInAnonymously();
      if (customerPublicInfo) {
        await CustomerRepository.current.updateAnonymousUid(customerPublicInfo.id, userCredential.user.uid);
        await updateLead(getStartedData);
      }
      else {
        await addNewLead(getStartedData, userCredential);
      }
      goToStep(steps.newLead.addressInput, undefined, true);
    }
    catch (error) {
      Sentry.captureException(error);
      console.error({ error });
      setGetStartedFormError(Customer.errorMessages.refreshAndTryAgain);
    }
    finally {
      setIsLoading(false);
    }
  };

  const addNewLead = async (getStartedData: InitialLeadData, userCredential: UserCredential) => {
    try {
      await CustomerRepository.current.createFromOnlineBooking(getStartedData, userCredential.user.uid, leadOriginToUse, utmParams);
      const currentCustomer = await CallableFunctions.current.public.getCurrentCustomer();
      if (!currentCustomer) {
        return;
      }
      setCustomer(currentCustomer.customer);
    }
    catch (error) {
      Sentry.captureException(error);
      console.error({ error });
      setGetStartedFormError(Customer.errorMessages.refreshAndTryAgain);
    }
  };

  const updateLead = async (getStartedData: InitialLeadData) => {
    try {
      const currentCustomer = await CallableFunctions.current.public.getCurrentCustomer();
      if (!currentCustomer) {
        return;
      }

      const email = normalizeEmail(getStartedData.email);
      const dataToUpdate = {
        firstname: getStartedData.firstName,
        lastname: getStartedData.lastName,
        email: email,
        text_ok: getStartedData.textOk,
        tos_pp_ok: getStartedData.termsOfServiceOk,
        "lead_info.origin": leadOriginToUse,
        "lead_info.outcome": LeadOutcome.open_address_page,
        "lead_info.utm_parameters": utmParams.length ? utmParams : undefined
      };
      await CustomerRepository.current.updateFromOnlineBooking(currentCustomer.customer.id, dataToUpdate);
      mixpanel.identify(email);
      mixpanel.people.set({
        fullname: getStartedData.firstName + " " + getStartedData.lastName,
        phone: getStartedData.phoneNumber,
        email: email,
        customer_id: currentCustomer.customer.id
      });

      const updatedLead = new Customer(currentCustomer.customer.id, {
        ...currentCustomer.customer.toData(),
        ...dataToUpdate,
        address: Customer.getDefaultAddress()
      });

      setCustomer(updatedLead);
    }
    catch (error) {
      Sentry.captureException(error);
      console.error({ error });
      setGetStartedFormError(Customer.errorMessages.refreshAndTryAgain);
    }
  };

  const checkInvitationCode = async () => {
    try {
      if (invitationCode) {
        setIsLoadingDiscount(true);
        const codeInfo = await CallableFunctions.current.public.getInvitationCodeInfo({ invitationCode });
        if (codeInfo) {
          setInviterCustomerName(codeInfo.customerName);
          if (customer) {
            await ReferralActivityRepository.current.create(
              customer,
              ReferralEventType.VisitUrl,
              `${process.env.REACT_APP_CUSTOMERS_BASE_URL}/online-booking-signup?invitation=${invitationCode}`,
              ReferralOrigin.customer
            );
          }
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
    finally {
      setIsLoadingDiscount(false);
    }
  };

  const checkDiscountCode = async () => {
    try {
      if (discountCodeParam) {
        setIsLoadingDiscount(true);

        const discount = await DiscountCodeRepository.current.getByCode(discountCodeParam);
        if (discount && customer && await DiscountCodeRepository.current.validateForCustomer(discount, customer)) {
          setDiscountCode(discount);

          if (customer) {
            await ReferralActivityRepository.current.create(
              customer,
              ReferralEventType.VisitUrl,
              `${process.env.REACT_APP_CUSTOMERS_BASE_URL}/online-booking-signup?discountCode=${discountCodeParam}`,
              ReferralOrigin.partnerships
            );
            setDiscountCodeParam(null);
          }
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setIsLoadingDiscount(false);
    }
  };

  useEffect(() => {
    if (errorLoadingContextData) {
      Swal.fire({
        title: "Error loading context data. Please refresh the page."
      });
    }
  }, [errorLoadingContextData]);

  useEffect(() => {
    setStepNumber(routeParams.stepNumber ? parseInt(routeParams.stepNumber) : steps.newLead.getStarted);
    setSubstepNumber(routeParams.substepNumber ? parseInt(routeParams.substepNumber) : null);
  }, [routeParams.stepNumber, routeParams.substepNumber]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [stepNumber, substepNumber]);

  useEffect(() => {
    const isEmailLinkSignIn = stepNumber === steps.newLead.codeVerification && isSignInWithEmailLink(authentication, window.location.href);
    if (stepNumber > steps.newLead.getStarted && (!customer || !isSignedIn) && !isEmailLinkSignIn) {
      goToStep(steps.newLead.getStarted);
    }
  }, [stepNumber, isSignedIn, customer, goToStep]);

  const trySignInWithEmailLink = async () => {
    const emailLink = window.location.href;
    const isSingInLink = isSignInWithEmailLink(authentication, emailLink);
    if (!isSingInLink)
      return;

    try {
      setIsLoading(true);
      const query = new URLSearchParams(search);
      const emailForSignIn = query.get("email");
      if (!emailForSignIn)
        throw new Error("Can't get email parameter. Please try again.");

      const userCredential = await signInWithEmailLink(authentication, emailForSignIn, emailLink);
      if (userCredential.user.isAnonymous) {
        const credential = EmailAuthProvider.credentialWithLink(emailForSignIn, emailLink);
        linkWithCredential(userCredential.user, credential);
      }

      const currentCustomer = await CallableFunctions.current.public.getCurrentCustomer();
      if (!currentCustomer) {
        throw new Error("Something unexpected happened when signing in via email link. Please try again.");
      }
      setCustomer(currentCustomer.customer);
      setPets(currentCustomer.pets);
      setSelectedServices(currentCustomer.pets.map(x => ({ petId: x.id, serviceId: x.getDefaultServiceId() })));

      goToStep(steps.newLead.acknowledgements);
    }
    catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    trySignInWithEmailLink();
    //HACK: We only want this useEffect to be triggered once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkInvitationCode();
    checkDiscountCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <>
      <OnlineBookingHeader
        isLead
        stepNumber={stepNumber}
        substepNumber={substepNumber}
        onHide={() => goToStep(steps.newLead.thankYou)}
      />

      {stepNumber === steps.newLead.getStarted &&
        <>
          {!substepNumber &&
            <LeadStepGetStarted
              onNext={handleFirstStep}
              isLoading={isLoading}
              formDataError={getStartedFormError}
              inviterCustomerName={inviterCustomerName}
              loadingDiscount={isLoadingDiscount}
            />}

          {substepNumber === steps.newLead.getStartedSubsteps.foundExisting &&
            <LeadExisting
              title="We found an account for that phone number"
              existingPhone={existingCustomerPhoneNumber}
            />}
        </>}

      {customer &&
        <OnlineBookingLeadInnerContainer
          stepNumber={stepNumber}
          substepNumber={substepNumber}
          customer={customer}
          pets={pets}
          setPets={setPets}
          selectedServices={selectedServices}
          setSelectedServices={setSelectedServices}
          onCustomerUpdate={setCustomer}
          goToStep={goToStep}
          invitationCode={invitationCode}
          setInvitationCode={setInvitationCode}
          discountCode={discountCode}
        />}
    </>
  );
}
