import notFound from "@marathon/web/images/404_not_found.png";

const NotFound = () => {
    return (
        <div style={{ textAlign: "center", maxWidth: 400, margin: "100px auto 80px auto" }}>
            <img src={notFound} alt="Not Found" />
        </div>
    );
};

export default NotFound;