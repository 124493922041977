import { useState, useCallback, useMemo, useEffect } from "react";
import * as Sentry from "@sentry/react";
import Swal from "sweetalert2";
import TagManager from "react-gtm-module";
import { WEEKLY_FREQUENCY_TYPE } from "@marathon/common/entities/Recurrence";
import { BookingSuggestion } from "@marathon/common/api/BookingSuggestion";
import LocalDate from "@marathon/common/utilities/LocalDate";
import { Recurrence } from "@marathon/common/entities/Recurrence";
import { Customer, ConversionName, CustomerAddressData, CustomerData, LeadOutcome, CustomerVerificationMode } from "@marathon/common/entities/Customer";
import GooglePlaces, { GoogleAddressValue } from "@marathon/web/utilities/GooglePlaces";
import { CustomerRepository } from "@marathon/client-side/repositories/CustomerRepository";
import { PetRepository } from "@marathon/client-side/repositories/PetRepository";
import { BookingSuggestions } from "@marathon/client-side/utilities/BookingSuggestions";
import { authentication } from "@marathon/web/firebaseApp";
import steps from "@marathon/common/utilities/OnlineBookingSteps";
import LeadStepAddress from "./LeadStepAddress";
import LeadStepPetDetails from "./LeadStepPetDetails";
import LeadStepAcknowledgements from "./LeadStepAcknowledgements";
import LeadStepPricing from "./LeadStepPricing";
import { ExceedsServiceAreaThankYou } from "./ExceedsServiceAreaThankYou";
import OnlineBookingCodeVerification from "../OnlineBookingCodeVerification";
import FindSuggestions from "../shared/FindSuggestions";
import ConfirmAppointment from "../shared/ConfirmAppointment";
import ConfirmSafety from "../shared/ConfirmSafety";
import ThankYou from "../shared/ThankYou";
import AppointmentOptions, { ONE_TIME_TYPE, RECURRENT_TYPE } from "../shared/AppointmentOptions";
import { LeadStepPetsAmount } from "./LeadStepPetsAmount";
import { useOnlineBookingContext } from "../OnlineBookingContext";
import { TimeZone } from "@marathon/common/helpers/timeZoneHelper";
import { useFetchSuggestions } from "../shared/useFetchSuggestions";
import { useTracker } from "../shared/tracker/useTracker";
import { validateForm } from "../PetForm";
import { isWithinServiceAreaForHub } from "@marathon/common/helpers/mapHelper";
import CallableFunctions from "@marathon/client-side/utilities/CallableFunctions";
import { getIsAuthenticated } from "../shared/authHelper";
import ThankYouAddCard from "../shared/ThankYouAddCard";
import { Alert, Snackbar } from "@mui/material";
import { Pet } from "@marathon/common/entities/Pet";
import { CreditCard } from "@marathon/common/entities/CreditCard";
import { Appointment, AppointmentTimeFilter } from "@marathon/common/entities/Appointment";
import { Service } from "@marathon/common/entities/Service";
import { PetInput } from "@marathon/client-side/entities/PetInput";
import { MixPanelEvent } from "@marathon/client-side/utilities/MixPanel";
import { UpdateDataInternal } from "@marathon/common/utilities/TypeUtils";
import { Groomer } from "@marathon/common/entities/Groomer";
import { checkCustomerIsBlacklist } from "../shared/checkBlacklist";
import { OnlineBookingFlowType } from "utilities/OnlineBookingFlowType";
import { DiscountCode } from "@marathon/common/entities/DiscountCode";

interface Props {
  stepNumber: number,
  substepNumber: number | null,
  customer: Customer,
  pets: Pet[],
  setPets: (pets: Pet[]) => void,
  selectedServices: { petId: string, serviceId: string }[],
  setSelectedServices: (selectedServices: { petId: string, serviceId: string }[]) => void,
  onCustomerUpdate: (updatedCustomer: Customer) => void,
  goToStep: (step: number, substep?: number, shouldReplace?: boolean | undefined, shouldRedirectToReturningFlow?: boolean | undefined) => void,
  invitationCode: string | null,
  setInvitationCode: (invitationCode: string | null) => void,
  discountCode?: DiscountCode,
}

export default function OnlineBookingLeadInnerContainer({
  stepNumber,
  substepNumber,
  customer,
  pets,
  setPets,
  selectedServices,
  setSelectedServices,
  onCustomerUpdate,
  goToStep,
  invitationCode,
  setInvitationCode,
  discountCode
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isGeocoding, setIsGeocoding] = useState(false);
  const [petFormError, setPetFormError] = useState<string>("");

  const [specificDate, setSpecificDate] = useState<LocalDate | null>(null);
  const [selectedGroomerId, setSelectedGroomerId] = useState<string | null>(null);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState<AppointmentTimeFilter | null>(null);

  const [bookedSuggestion, setBookedSuggestion] = useState<BookingSuggestion | null>(null);
  const [appointmentType, setAppointmentType] = useState<typeof ONE_TIME_TYPE | typeof RECURRENT_TYPE>(RECURRENT_TYPE);
  const [frequency, setFrequency] = useState<{ interval: number, type: string }>({ interval: 4, type: WEEKLY_FREQUENCY_TYPE });
  const [appointmentNotes, setAppointmentNotes] = useState("");
  const [appointment, setAppointment] = useState<Appointment | Recurrence | null>(null);
  const [cards, setCards] = useState<CreditCard[]>([]);
  const [showCreditCardSuccessMessage, setShowCreditCardSuccessMessage] = useState(false);

  const { pricingPresets, breeds, groomers, hubs, services, mobileServiceFee, discounts, isFromSms, onSignIn, clickId } = useOnlineBookingContext();

  if (!mobileServiceFee)
    throw new Error("Mobile service fee configuration is required at this point");

  const getCustomerTimeZone = useCallback(() => {
    return hubs.find(x => x.id === customer.address.drive_time?.hub_id)?.time_zone ?? TimeZone.PacificTime;
  }, [hubs, customer.address.drive_time?.hub_id]);

  const { suggestions, isFetchingSuggestions } = useFetchSuggestions({
    customerId: customer.id,
    selectedServices,
    specificDate: specificDate || undefined,
    selectedGroomerId: selectedGroomerId || undefined,
    selectedTimeFilter: selectedTimeFilter || undefined,
    selectedAddress: customer.address,
    isAuthenticated: getIsAuthenticated()
  });

  const tryUpdateLeadInfoOutcome = async (initialStatus: LeadOutcome, finalStatus: LeadOutcome) => {
    if (!customer.lead_info?.outcome || customer.lead_info.outcome === initialStatus) {
      try {
        setIsLoading(true);
        await CustomerRepository.current.updateFromOnlineBooking(customer.id, { "lead_info.outcome": finalStatus });
        onCustomerUpdate(
          new Customer(customer.id, {
            ...customer.toData(),
            lead_info: { ...customer.lead_info, outcome: finalStatus }
          })
        );
      }
      catch (error) {
        Sentry.captureException(error);
      }
      finally {
        setIsLoading(false);
      }
    }
  };

  const getTotalServicePrice = useCallback(() => {
    return Service.getServicesFinalPrice(selectedServices, pets, customer, mobileServiceFee);
  }, [selectedServices, pets, customer, mobileServiceFee]);

  const getServiceNames = useCallback(() => {
    return Service.getServicesLabel(services, selectedServices.map(x => x.serviceId));
  }, [services, selectedServices]);

  const getFindSuggestionTrackEventProperties = () => {
    return { new_user: true, groom_price: getTotalServicePrice(), service: getServiceNames() };
  };

  const getInfo = useMemo(() => {
    return {
      customer: {
        name: customer.fullname(),
        email: customer.email,
        petQuantity: pets.length,
        address: customer.formattedAddress(),
        authenticationType: authentication.currentUser?.email
          ? CustomerVerificationMode.emailLink
          : CustomerVerificationMode.phoneCode
      },
      dogs: pets.map(x => {
        return {
          breedName: breeds.find(y => y.id === x.breed_id)?.name,
          age: x.getAge() || undefined,
          gender: x.gender,
          weight: x.weight,
        };
      }),
      servicePrice: getTotalServicePrice(),
      serviceNames: getServiceNames(),
      appointment: {
        arrivalTime: bookedSuggestion?.arrivalTime,
        timeZone: bookedSuggestion?.timeZone
      },
      isFromSms: isFromSms,
    };
  }, [bookedSuggestion?.arrivalTime, bookedSuggestion?.timeZone, breeds, customer, getServiceNames, getTotalServicePrice, isFromSms, pets]);

  const trackEvent = useTracker({ flowType: OnlineBookingFlowType.newCustomer, stepNumber, substepNumber: substepNumber || undefined, info: getInfo });

  const deleteExistingPets = async (customerId: string, petIds: string[]) => {
    await Promise.all(petIds.map(async (petId) => {
      return await PetRepository.current.remove(customerId, petId);
    }));
  };

  const savePets = async () => {
    try {
      const currentCustomer = await CallableFunctions.current.public.getCurrentCustomer();
      if (currentCustomer) {
        const existingPetIds = currentCustomer.pets.map(x => x.id);
        await deleteExistingPets(currentCustomer.customer.id, existingPetIds);
      }

      const tempSelectedServices: { petId: string, serviceId: string }[] = [];
      const tempPets: Pet[] = [];
      for (const pet of pets) {
        const breed = breeds.find(x => x.id === pet.breed_id);
        if (!breed) throw new Error(`Breed not valid ${pet.breed_id}`);

        const customerHub = hubs.find(x => x.id === customer.address.drive_time?.hub_id);
        const pricingPreset = pricingPresets.find(x => x.id === customerHub?.pricing_preset_id);
        if (!pricingPreset) throw new Error("PricingPreset required at this point");

        pet.services = PetRepository.getServices(breed, pet.weight, pricingPreset);

        const newPet = await PetRepository.current.create(customer.id, PetRepository.toInput(pet));
        tempPets.push(newPet);
        tempSelectedServices.push({ petId: newPet.id, serviceId: newPet.getDefaultServiceId() });
      }
      setSelectedServices(tempSelectedServices);
      setPets(tempPets);

      await CustomerRepository.current.updateFromOnlineBooking(
        customer.id, {
        "lead_info.outcome": LeadOutcome.open_lead_verification
      });
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      setPetFormError(Customer.errorMessages.tryAgainOrCallUs);
      setIsLoading(false);
      return;
    }
  };

  const handleAddressChange = (address: CustomerAddressData) => {
    onCustomerUpdate(
      new Customer(customer.id, {
        ...customer.toData(),
        address
      })
    );
  };

  const handleAddressNextButton = async () => {
    try {
      setIsLoading(true);

      if (!customer.address.place_id) {
        throw new Error(`Customer ${customer.id} has no place_id in address ${customer.address.address1}`);
      }

      const hub = hubs.find(x => x.id === customer.address.drive_time?.hub_id);
      if (!isWithinServiceAreaForHub(customer.coordinates(), hub)) {
        await handleJoinWaitlist();
        return;
      }

      const toUpdate = {
        address: customer.address,
        "lead_info.outcome": LeadOutcome.open_dog_info_page
      };
      await CustomerRepository.current.updateFromOnlineBooking(customer.id, { ...toUpdate });
    }
    catch (error) {
      Sentry.captureException(error);
      Swal.fire({ title: Customer.errorMessages.tryAgainOrCallUs });
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    goToStep(steps.newLead.petsAmount);
  };

  const handleAddressAutocompleteChange = async (selected: { value: GoogleAddressValue } | null) => {
    if (selected) {
      try {
        setIsGeocoding(true);
        const address = await GooglePlaces.getCustomerAddressByGoogleAutocomplete(selected, hubs);

        const toUpdate = {
          address,
          twilio_hub_id: address.drive_time?.hub_id,
          "lead_info.outcome": address.drive_time
            ? LeadOutcome.open_address_page
            : LeadOutcome.closed_lost_geography_limitation
        };

        await CustomerRepository.current.updateFromOnlineBooking(customer.id, { ...toUpdate });

        setIsGeocoding(false);
        handleAddressChange(address);
      } catch (error) {
        Swal.fire({ title: Customer.errorMessages.tryAgainOrCallUs });
        Sentry.captureException(error);
        console.error("Error", error);
      }
    }
    else {
      handleAddressChange(Customer.getDefaultAddress());
    }
  };

  const handleJoinWaitlist = async () => {
    try {
      setIsLoading(true);
      await CustomerRepository.current.updateFromOnlineBooking(
        customer.id,
        { "lead_info.outcome": LeadOutcome.closed_lost_geography_limitation_waitlist }
      );
    }
    catch (error) {
      Sentry.captureException(error);
      Swal.fire({ title: Customer.errorMessages.tryAgainOrCallUs });
      setIsLoading(false);
    }
    setIsLoading(false);
    goToStep(steps.newLead.addressInput, steps.newLead.addressInputSubsteps.thankYou);
  };

  const handleCodeVerification = async () => {
    await CustomerRepository.current.updateFromOnlineBooking(customer.id, { "lead_info.outcome": LeadOutcome.open_pre_pricing_page });
    goToStep(steps.newLead.acknowledgements);
  };

  const handlePetsAmountChange = (petsAmount: number) => {
    setPets(
      [...Array(petsAmount).fill(PetRepository.getInstanceFromInput("", customer.id, { name: "" }))]
    );
  };

  const handlePetsChange = (index: number, newValue: PetInput) => {
    setPets(
      pets.map((pet: Pet, i: number) =>
        i === index
          ? PetRepository.getInstanceFromInput("", customer.id, { ...PetRepository.toInput(pet), ...newValue })
          : pet
      )
    );
  };

  const handleNextPet = async (index: number) => {
    const currentPet = pets[index];
    if (!validateForm(PetRepository.toInput(currentPet), setPetFormError))
      return;

    if (substepNumber === pets.length) {
      try {
        setIsLoading(true);
        await savePets();
        await onSignIn({ phoneNumber: customer.phone });
        goToStep(steps.newLead.codeVerification);
      }
      catch (error) {
        Sentry.captureException(error);
        setPetFormError(Customer.errorMessages.refreshAndTryAgain);
        return;
      }
      finally {
        setIsLoading(false);
      }
    } else {
      goToStep(steps.newLead.petDetails, substepNumber ? substepNumber + 1 : 1);
    }
  };

  const handleViewPricing = async () => {
    try {
      setIsLoading(true);
      await CustomerRepository.current.updateFromOnlineBooking(customer.id, { "lead_info.outcome": LeadOutcome.open_pricing_page });
    } catch (error) {
      Sentry.captureException(error);
      return;
    }
    finally {
      setIsLoading(false);
    }
    goToStep(steps.newLead.pricing);
  };

  const goToBookAppointment = async () => {
    try {
      const toUpdate: UpdateDataInternal<CustomerData> = {
        "lead_info.outcome": LeadOutcome.open_find_appointment
      };
      if (clickId) {
        toUpdate.ads_click_id = { ...clickId, conversion_name: ConversionName.BookedOnline };
      }
      await CustomerRepository.current.updateFromOnlineBooking(customer.id, toUpdate);
      onCustomerUpdate(
        new Customer(customer.id, {
          ...customer.toData(),
          lead_info: { ...customer.lead_info, outcome: LeadOutcome.open_find_appointment }
        })
      );
    } catch (error) {
      Sentry.captureException(error);
      Swal.fire({ title: Customer.errorMessages.tryAgainOrCallUs });
      return;
    }

    goToStep(steps.newLead.suggestions);
  };

  const bookAppointmentInternal = async () => {
    try {
      if (!bookedSuggestion)
        throw new Error("Suggestion is required at this point");

      const groomer = groomers.find(x => x.id === bookedSuggestion.groomer.id);
      if (!groomer)
        throw new Error(`Groomer ${bookedSuggestion.groomer.id} not found`);

      if (!customer)
        throw new Error("Customer is required at this point");

      const blackList = await checkCustomerIsBlacklist();
      if (blackList) {
        goToStep(steps.newLead.getStarted);
        throw new Error("Customer not valid for online booking");
      }

      const bookedAppointment = await BookingSuggestions.createAppointment({
        suggestion: bookedSuggestion,
        groomer,
        customer,
        pets,
        services,
        isFromSignup: true,
        assignments: selectedServices,
        mobileServiceFee,
        isFromSms,
        isFromOnlineBooking: true,
        frequency: appointmentType === RECURRENT_TYPE ? frequency : null,
        notesFromCustomer: appointmentNotes,
        discounts,
        invitationCode: invitationCode || undefined,
        hubs,
        discountCode
      });

      if (invitationCode)
        setInvitationCode(null);

      setAppointment(bookedAppointment);
    }
    catch (error) {
      Sentry.captureException(error);
      console.error(error);
      Swal.fire({ title: "Sorry, an unexpected error has occurred. Please try again." });
      throw error;
    }
  };

  const handlePaymentMethodAddedSuccess = () => {
    setShowCreditCardSuccessMessage(true);
    goToStep(steps.newLead.thankYou);
  };

  const filteredGroomers = useMemo(() => {
    return Groomer.filterForBookingSuggestions(
      groomers,
      customer,
      customer.address,
      selectedServices.map(x => x.serviceId)
    );
  }, [selectedServices, groomers, customer]);

  useEffect(() => {
    if (appointment?.id) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataLayerData: any = {
        event: "Reservation",
        reservationId: `[${appointment.id}]`,
        reservationPrice: `[${appointment.finalPrice()}]`,
        email: customer.email,
      };
      if (clickId) {
        dataLayerData[clickId.type] = clickId.value;
      }
      TagManager.dataLayer({
        dataLayer: dataLayerData,
      });
    }
    //HACK: We only want this useEffect to be triggered when change [appointment, customer.email].
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment, customer.email]);

  return (
    <>
      {stepNumber === steps.newLead.addressInput &&
        <>
          {!substepNumber &&
            <LeadStepAddress
              address={customer.address}
              handleChange={(x) => handleAddressChange(x as CustomerAddressData)}
              onAddressAutocompleteChange={handleAddressAutocompleteChange}
              onNext={handleAddressNextButton}
              isLoading={isLoading}
              isGeocoding={isGeocoding}
            />}

          {stepNumber === steps.newLead.addressInput && substepNumber === steps.newLead.addressInputSubsteps.thankYou &&
            <ExceedsServiceAreaThankYou />}
        </>}

      {stepNumber === steps.newLead.petsAmount &&
        <LeadStepPetsAmount
          petsAmount={pets.length}
          setPetsAmount={handlePetsAmountChange}
          onNext={() => goToStep(steps.newLead.petDetails, 1)}
        />}

      {stepNumber === steps.newLead.petDetails &&
        <LeadStepPetDetails
          formError={petFormError}
          pets={pets}
          editingPetIndex={substepNumber ? substepNumber - 1 : 0}
          handlePetChange={handlePetsChange}
          onNext={handleNextPet}
          breeds={breeds}
          isLoading={isLoading}
        />}

      {stepNumber === steps.newLead.codeVerification &&
        <OnlineBookingCodeVerification
          flowType={OnlineBookingFlowType.newCustomer}
          onNext={handleCodeVerification}
          phoneNumber={customer.phone}
          initialEmail={customer.email}
        />}

      {stepNumber === steps.newLead.acknowledgements &&
        <LeadStepAcknowledgements pets={pets} onNext={handleViewPricing} isLoading={isLoading} services={services} />}

      {stepNumber === steps.newLead.pricing &&
        <LeadStepPricing
          hasMobileServiceFee={!customer.skip_mobile_service_fee}
          customer={customer}
          mobileServiceFee={mobileServiceFee}
          handleChangeSelectedService={
            (petId: string, serviceId: string) =>
              setSelectedServices(selectedServices.map(x => x.petId === petId ? { ...x, serviceId } : x))
          }
          selectedServices={selectedServices}
          pets={pets ?? []}
          services={services}
          onNext={goToBookAppointment}
          discounts={discounts}
          invitationCode={invitationCode}
          hubs={hubs}
          discountCode={discountCode}
        />}

      {stepNumber === steps.newLead.suggestions &&
        <FindSuggestions
          isLoading={isFetchingSuggestions}
          suggestions={suggestions}
          groomers={filteredGroomers}
          specificDate={specificDate}
          setSpecificDate={setSpecificDate}
          selectedGroomerId={selectedGroomerId}
          setSelectedGroomerId={setSelectedGroomerId}
          selectedTimeFilter={selectedTimeFilter}
          setSelectedTimeFilter={setSelectedTimeFilter}
          onBookAppointmentClick={() => goToStep(steps.newLead.appointmentOptions)}
          setBookedSuggestion={setBookedSuggestion}
          tryUpdateLeadInfoOutcome={tryUpdateLeadInfoOutcome}
          trackEvent={trackEvent}
          trackEventData={{
            eventName: MixPanelEvent.ob_new_dates,
            properties: getFindSuggestionTrackEventProperties
          }}
          customerTimeZone={getCustomerTimeZone()}
        />}

      {stepNumber === steps.newLead.appointmentOptions && bookedSuggestion &&
        <AppointmentOptions
          customer={customer}
          suggestion={bookedSuggestion}
          onConfirmOptions={() => goToStep(steps.newLead.confirmation)}
          handleFrequency={f => setFrequency(f)}
          tryUpdateLeadInfoOutcome={tryUpdateLeadInfoOutcome}
          frequency={frequency}
          appointmentType={appointmentType}
          handleChangeAppointmentType={(type) => setAppointmentType(type)}
          discounts={discounts}
          hubs={hubs}
          mobileServiceFee={mobileServiceFee}
          invitationCode={invitationCode || undefined}
          discountCode={discountCode}
        />}

      {stepNumber === steps.newLead.confirmation && bookedSuggestion &&
        <ConfirmAppointment
          customer={customer}
          suggestion={bookedSuggestion}
          onConfirmBooking={() => goToStep(steps.newLead.thankYou)}
          onGoToSafety={() => goToStep(steps.newLead.safety)}
          pets={pets}
          services={services}
          selectedServices={selectedServices}
          mobileServiceFee={mobileServiceFee}
          tryUpdateLeadInfoOutcome={tryUpdateLeadInfoOutcome}
          appointmentNotes={appointmentNotes}
          setAppointmentNotes={setAppointmentNotes}
          bookAppointment={bookAppointmentInternal}
          frequency={appointmentType === RECURRENT_TYPE ? frequency : null}
          discounts={discounts}
          invitationCode={invitationCode || undefined}
          selectedAddress={customer.address}
          hubs={hubs}
          discountCode={discountCode}
        />}

      {stepNumber === steps.newLead.safety &&
        <ConfirmSafety
          customer={customer}
          bookAppointment={bookAppointmentInternal}
          onNext={() => goToStep(steps.newLead.thankYou)}
        />}

      {stepNumber === steps.newLead.thankYou &&
        <>
          {substepNumber === steps.newLead.thankYouSubsteps.createPaymentMethod &&
            <ThankYouAddCard
              customer={customer}
              flowType={OnlineBookingFlowType.newCustomer}
              setCards={setCards}
              cards={cards}
              handlePaymentMethodAddedSuccess={handlePaymentMethodAddedSuccess}
              hubs={hubs} />}
          {!substepNumber &&
            <ThankYou
              appointment={appointment}
              suggestion={bookedSuggestion}
              pets={pets}
              trackEvent={trackEvent}
              flowType={OnlineBookingFlowType.newCustomer}
              hubs={hubs}
              addNewPaymentMethod={() => goToStep(steps.newLead.thankYou, steps.newLead.thankYouSubsteps.createPaymentMethod)}
              goToMyAccount={() => goToStep(steps.returning.welcome, undefined, undefined, true)}
              cards={cards}
            />}
        </>}
      <Snackbar open={showCreditCardSuccessMessage} autoHideDuration={6000} onClose={() => setShowCreditCardSuccessMessage(false)}>
        <Alert severity="success">
          Thanks! Your credit card has been added
        </Alert>
      </Snackbar>
      <div id="recaptcha-container"></div>
    </>
  );
}