import { container, inject, singleton } from "tsyringe";
import { DocResult, INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { IFirestoreService } from "./IFirestoreService";
import { CsatFeedbackURL, Hub, HubData, HubStatus } from "@marathon/common/entities/Hub";
import { UpdateDataInternal } from "@marathon/common/utilities/TypeUtils";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";
import { HubInput } from "../entities/HubInput";

const mapEntity = function (snapshot: DocResult<HubData>) {
    return new Hub(snapshot.id, snapshot.data);
};

interface SearchOptions {
    onlyVisibleFromOnlineBooking?: boolean;
    includeInactive?: boolean;
}

@singleton()
export class HubRepository {
    private firestoreService: IFirestoreService<HubData>;
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<HubData>) {
        injectedService.collectionPath = CollectionPaths.Hubs;
        this.firestoreService = injectedService;
    }
    static get current() {
        return container.resolve(HubRepository);
    }
    private getDataFromInput(input: HubInput): HubData {
        if (!input.vertices) throw new Error("A set of vertices is required at this point");
        if (!input.timeZone) throw new Error("Time zone is required at this point");
        if (!input.hubGroup) throw new Error("Hub group is required at this point");
        return ({
            name: input.name,
            acronym: input.acronym,
            full_address: input.address,
            place_id: input.placeId,
            time_zone: input.timeZone,
            coordinates: input.coordinates,
            phone_number: input.phone,
            pricing_preset_id: input.pricingPresetId,
            vertices: input.vertices,
            square_location_id: input.squareLocationId,
            vcard_url: input.vCardUrl,
            google_csat_feedback_url: input.googleCsatFeedbackUrl,
            extra_csat_feedback_urls: input.extraCsatFeedbackUrls.map(x => ({ origin: x.origin, url: x.url.trim() })).filter(x => x.url),
            hub_group: input.hubGroup,
            goal: Hub.defaultSalesGoal(),
            status: HubStatus.Active
        });
    }
    async createFromHubInput(inputData: HubInput) {
        HubRepository.validateInput(inputData);
        const data = this.getDataFromInput(inputData);
        return await this.firestoreService.create(data);
    }
    async updateFromHubInput(id: string, inputData: HubInput) {
        HubRepository.validateInput(inputData);
        const data = this.getDataFromInput(inputData);
        await this.firestoreService.update(id, data);
    }
    async getById(id: string) {
        const doc = await this.firestoreService.getById(id);
        if (!doc)
            throw new Error(`Hub not found: ${id}`);
        return mapEntity(doc);
    }
    async search(options?: SearchOptions) {
        const docs = await this.firestoreService.search();

        const hubs = docs
            .map((x) => mapEntity(x))
            .filter((x) =>
                options?.onlyVisibleFromOnlineBooking
                    ? !x.hide_from_online_booking
                    : true
            ).filter((x) =>
                options?.includeInactive
                    ? true
                    : x.status === HubStatus.Active
            );

        return hubs.sortByFieldAscending(x => x.name);
    }
    async markAsInactive(id: string) {
        await this.firestoreService.update(id, { status: HubStatus.Inactive });
    }
    async update(id: string, toUpdate: UpdateDataInternal<HubData>) {
        await this.firestoreService.update(id, toUpdate);
    }
    async updateGoal(id: string, goal: number) {
        await this.firestoreService.update(id, { "goal.value": goal });
    }
    async updateExtraCsatFeedbackUrls(id: string, feedbackUrls: CsatFeedbackURL[]) {
        await this.firestoreService.update(id, { extra_csat_feedback_urls: feedbackUrls });
    }
    static toInput(hub: Hub): HubInput {
        const origins = Hub.customCsatLeadOrigins();
        const initialUrls = origins.map((origin) => ({
            origin,
            url: hub?.extra_csat_feedback_urls?.find((x) => x.origin === origin)?.url || "",
        }));
        return ({
            id: hub.id,
            acronym: hub.acronym,
            name: hub.name,
            address: hub.full_address,
            placeId: hub.place_id,
            timeZone: hub.time_zone,
            coordinates: hub.coordinates,
            phone: hub.phone_number,
            pricingPresetId: hub.pricing_preset_id,
            vertices: hub.vertices,
            squareLocationId: hub.square_location_id,
            vCardUrl: hub.vcard_url,
            googleCsatFeedbackUrl: hub.google_csat_feedback_url,
            extraCsatFeedbackUrls: initialUrls,
            hubGroup: hub.hub_group
        });
    }
    static getDefaultInput(): HubInput {
        const origins = Hub.customCsatLeadOrigins();
        const initialUrls = origins.map((origin) => ({ origin, url: "" }));
        return {
            acronym: "",
            name: "",
            address: "",
            placeId: "",
            coordinates: { lat: 34.0613495, lng: -118.4420712 },
            phone: "",
            pricingPresetId: "",
            vertices: [],
            squareLocationId: "",
            vCardUrl: "",
            googleCsatFeedbackUrl: "",
            extraCsatFeedbackUrls: initialUrls
        };
    }
    static validateInput(input: HubInput) {
        if (!input.name) throw new Error("Name is required");
        if (!input.acronym) throw new Error("Acronym is required");
        if (!input.phone || !input.phone.match(/^\d{10}$/)) throw new Error("Phone number must be 10 digits");
        if (!input.address) throw new Error("Full address is required");
        if (!input.coordinates) throw new Error("Coordinates are required");
        if (!input.vertices || input.vertices.length < 3) throw new Error("Vertices are required");
        if (!input.pricingPresetId) throw new Error("Pricing preset ID is required");
        if (!input.squareLocationId) throw new Error("Square location ID is required");
        if (!input.hubGroup) throw new Error("Hub group is required");
        if (!input.googleCsatFeedbackUrl) throw new Error("Google CSAT URL is required");
        if (!input.vCardUrl) throw new Error("vCard URL is required");
    }
}