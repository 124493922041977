import { useState } from "react";
import TextField from "@mui/material/TextField";
import { Button } from "@marathon/web/components/Button";
import {
  Box,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { PhoneMaskedInput } from "@marathon/web/components/PhoneMaskedInput";
import { Loading } from "@marathon/web/components/Loading";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { ContentWrapper, TitleWrapper, FieldsetWrapper } from "../shared/CommonWrappers";
import { InitialLeadData } from "@marathon/client-side/repositories/CustomerRepository";
import { REFERRAL_DISCOUNT_VALUE } from "@marathon/common/entities/Discount";
import { Customer } from "@marathon/common/entities/Customer";
import PhoneNumbers from "@marathon/common/utilities/PhoneNumbers";

interface Props {
  onNext: (getStartedData: InitialLeadData) => Promise<void>,
  isLoading: boolean,
  formDataError: string,
  loadingDiscount: boolean,
  inviterCustomerName?: string
}

export default function LeadStepGetStarted({
  onNext,
  isLoading,
  formDataError,
  inviterCustomerName,
  loadingDiscount
}: Props) {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLasttName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [termsOfServiceOk, setTermsOfServiceOk] = useState(false);
  const [textOk, setTextOk] = useState(true);
  const [formError, setFormError] = useState<string>("");
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);

  const classes = useStyles();

  const isValidCustomer = () => {

    if (!firstName) {
      setFormError("First Name is required");
      return false;
    }
    else if (!lastName) {
      setFormError("Last Name is required");
      return false;
    }
    else if (!email) {
      setFormError("Email is required");
      return false;
    }
    else if (!Customer.isValidEmail(email.trim())) {
      setFormError("Email is invalid");
      return false;
    }
    else if (!phoneNumber) {
      setFormError("Phone is required");
      return false;
    }
    else if (!isValidPhoneNumber) {
      setFormError("Phone is invalid");
      return false;
    }

    setFormError("");
    return true;
  };

  if (loadingDiscount)
    return <Loading />;

  const renderAcknowledgementLink = (path: string, label: string) => {
    return (
      <a style={{ color: "#000" }} target="_blank" rel="noopener noreferrer" href={`https://www.barkbus.com/${path}`}>{label}</a>
    );
  };

  return (
    <OnlineBookingContentWrapper>
      <TitleWrapper
        title="Let’s create your account"
        subtitle={inviterCustomerName
          ? `You’ve been invited by ${inviterCustomerName}! Book your first appointment and receive a $${REFERRAL_DISCOUNT_VALUE} discount.`
          : "Signup only takes a couple minutes."}
      />
      <ContentWrapper>
        <FieldsetWrapper>
          <TextField
            className={classes.textFieldWithoutMargin}
            autoComplete="first-name"
            variant="outlined"
            color="primary"
            size="medium"
            label="First Name"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
          <TextField
            className={classes.textField}
            autoComplete="family-name"
            variant="outlined"
            color="primary"
            size="medium"
            label="Last Name"
            value={lastName}
            onChange={e => setLasttName(e.target.value)}
          />
          <TextField
            className={classes.textField}
            autoComplete="tel-national"
            variant="outlined"
            color="primary"
            size="medium"
            label="Phone Number"
            value={phoneNumber}
            onChange={event => {
              const newValue = event.target.value;
              setPhoneNumber(newValue);
              setIsValidPhoneNumber(PhoneNumbers.isValidNormalizedPhone(newValue));
            }}
            InputProps={{
              inputComponent: PhoneMaskedInput,
              inputProps: { doNotShowMaskAsPlaceholder: true }
            }}
          />
          <TextField
            className={classes.textField}
            autoComplete="email"
            variant="outlined"
            color="primary"
            size="medium"
            label="Email Address"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          {(formError || formDataError) &&
            <Box mt={2}>
              <FormHelperText>{formError || formDataError}</FormHelperText>
            </Box>}
          <Box mt={2.5}>
            <FormGroup>
              <FormControlLabel
                style={{ width: "100%" }}
                control={
                  <Box>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        checked={termsOfServiceOk}
                        onChange={v => setTermsOfServiceOk(v.target.checked)}
                      />
                      <span className="check"></span>
                    </label>
                  </Box>
                }
                label={
                  <Box
                    fontSize="15px"
                    color="#000000"
                    textAlign="left"
                    lineHeight="normal"
                    ml={5}
                  >
                    I agree to the {renderAcknowledgementLink("terms-of-service", "Barkbus Terms of Service")} and {renderAcknowledgementLink("privacy-policy", "Privacy Policy")}
                  </Box>
                }
              />
              <FormControlLabel
                style={{ width: "100%", marginTop: 20 }}
                control={
                  <Box>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        checked={textOk}
                        onChange={v => setTextOk(v.target.checked)}
                      />
                      <span className="check"></span>
                    </label>
                  </Box>}
                label={
                  <Box
                    fontSize="15px"
                    color="#000000"
                    textAlign="left"
                    lineHeight="normal"
                    ml={5}
                  >
                    Emails and texts about my dog are ok
                  </Box>}
              />
            </FormGroup>
          </Box>
          <Button
            showSpinner={isLoading}
            variant="contained"
            className={classes.button}
            disableElevation
            onClick={async () => {
              if (isValidCustomer())
                await onNext({ firstName, lastName, phoneNumber, email, termsOfServiceOk, textOk });
            }}
            disabled={!termsOfServiceOk}
          >
            Continue
          </Button>
        </FieldsetWrapper>
      </ContentWrapper>
    </OnlineBookingContentWrapper>
  );
}