import { MessageData, MessageSender, MessageParentType, MessageType, MessageTypeFilter, MessageInputData, Message } from "@marathon/common/entities/Message";
import { shouldBeUnreachable } from "@marathon/common/helpers/typesHelper";
import { UpdateDataInternal } from "@marathon/common/utilities/TypeUtils";
import { FilterOperator, getFilter, getOrderBy, OrderBy, QueryFilter } from "@marathon/common/api/QueryFilter";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";
import DeviceStorageCache from "@marathon/client-side/utilities/DeviceStorageCache";
import { DocResult } from "./IFirestoreService";
import type { IFirestoreService } from "./IFirestoreService";

export abstract class BaseMessageRepository {
    protected firestoreService: IFirestoreService<MessageData>;
    constructor(firestoreService: IFirestoreService<MessageData>, parentCollectionPath: MessageParentType) {
        firestoreService.collectionPath = CollectionPaths.Messages;
        firestoreService.parentCollectionPath = parentCollectionPath;
        this.firestoreService = firestoreService;
    }
    toEntity(doc: DocResult<MessageData>) {
        if (!doc.parentId || !doc.baseCollection)
            throw new Error("Document parent information is required at this point");

        return new Message(
            doc.id,
            doc.baseCollection as MessageParentType,
            doc.parentId,
            doc.data
        );
    }
    async createSms(input: MessageInputData) {
        const data: MessageData = {
            content: input.content,
            created_at: new Date(),
            sender: MessageSender.Barkbus,
            user_id: DeviceStorageCache.getNonEmptyCurrentUserUid(),
            type: MessageType.SMS,
            secondary_contact: input.secondaryContact,
            appointment_id: input.appointmentId,
            suggested_appointment_id: input.suggestedAppointmentId,
            ...Message.getMediaFields(input.files)
        };
        return await this.add(data, input.parentId);
    }
    async createNote(input: MessageInputData) {
        const data: MessageData = {
            content: input.content,
            created_at: new Date(),
            sender: MessageSender.Barkbus,
            user_id: DeviceStorageCache.getNonEmptyCurrentUserUid(),
            type: MessageType.Note,
            ...Message.getMediaFields(input.files)
        };
        return await this.add(data, input.parentId);
    }
    async createManagerNote(input: MessageInputData) {
        const data: MessageData = {
            content: input.content,
            created_at: new Date(),
            sender: MessageSender.Barkbus,
            user_id: DeviceStorageCache.getNonEmptyCurrentUserUid(),
            type: MessageType.ManagerNote,
            ...Message.getMediaFields(input.files)
        };
        return await this.add(data, input.parentId);
    }
    async createActivity(input: MessageInputData) {
        if (!input.outcome)
            throw new Error("Outcome is required for activity messages");

        const data: MessageData = {
            content: input.content,
            created_at: new Date(),
            sender: MessageSender.Barkbus,
            user_id: DeviceStorageCache.getNonEmptyCurrentUserUid(),
            type: MessageType.Activity,
            outcome: input.outcome
        };
        return await this.add(data, input.parentId);
    }
    protected async add(message: MessageData, parentId: string) {
        const id = await this.firestoreService.create(message, parentId);

        const baseCollection = this.firestoreService.parentCollectionPath;
        if (!baseCollection)
            throw new Error("Parent path should be defined at this point");

        return this.toEntity({
            id,
            parentId,
            data: message,
            baseCollection
        });
    }
    async update(parentId: string, messageId: string, data: UpdateDataInternal<MessageData>) {
        await this.firestoreService.update(messageId, data, parentId);
    }
    async remove(parentId: string, messageId: string) {
        await this.firestoreService.hardDelete(messageId, parentId);
    }
    listenChanges(
        parentId: string,
        documentCount: number,
        typeFilter: MessageTypeFilter,
        callback: (messages: Message[]) => void,
        onlyWithAttachments?: boolean): () => void {
        const constraints: QueryFilter<MessageData>[] = [];

        switch (typeFilter) {
            case MessageTypeFilter.All:
                constraints.push(getFilter("type", FilterOperator.in, Message.allVisibleTypes()));
                break;
            case MessageTypeFilter.Messages:
                constraints.push(getFilter("type", FilterOperator.in, Message.smsCompatibleTypes()));
                break;
            case MessageTypeFilter.Notes:
                constraints.push(getFilter("type", FilterOperator.equal, MessageType.Note));
                break;
            case MessageTypeFilter.ManagerNotes:
                constraints.push(getFilter("type", FilterOperator.equal, MessageType.ManagerNote));
                break;
            case MessageTypeFilter.Activity:
                constraints.push(getFilter("type", FilterOperator.equal, MessageType.Activity));
                break;
            case MessageTypeFilter.GroomerView:
                constraints.push(getFilter("type", FilterOperator.in, [MessageType.SMS, MessageType.Note]));
                break;
            default:
                shouldBeUnreachable(typeFilter);
        }

        if (onlyWithAttachments) {
            constraints.push(getFilter("has_media", FilterOperator.equal, true));
        }

        const orders: OrderBy<MessageData>[] = [getOrderBy("created_at", "desc")];
        const limit = documentCount;
        return this.firestoreService
            .onQuerySnapshot({ filters: constraints, orders, limit }, (docs: DocResult<MessageData>[]) => {
                callback(docs.map(doc => this.toEntity(doc)));
            }, parentId);
    }
    async createImpersonatedSms(parentId: string, content: string) {
        const sender = this.firestoreService.parentCollectionPath === CollectionPaths.Customers ? MessageSender.Customer : MessageSender.Groomer;
        const data: MessageData = {
            content,
            created_at: new Date(),
            sender: sender,
            user_id: DeviceStorageCache.getNonEmptyCurrentUserUid(),
            type: MessageType.SMS,
            media_urls: [],
            media_types: []
        };
        return await this.add(data, parentId);
    }
}