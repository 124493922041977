import { forwardRef } from "react";
import { IMaskInput } from "react-imask";
import { InputBaseComponentProps } from "@mui/material/InputBase";
import { Pet } from "@marathon/common/entities/Pet";

interface BirthdayMaskedInputProps extends InputBaseComponentProps {
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  doNotShowMaskAsPlaceholder?: true;
}

const DEFAULT_EMPTY_VALUE = "__/__";

export const BirthdayMaskedInput = forwardRef<HTMLInputElement, BirthdayMaskedInputProps>(
  function BirthdayMaskedInput({ name, onChange, doNotShowMaskAsPlaceholder, ...other }, ref) {

    const handleAccept = (value: string) => {
      const emptyValue = doNotShowMaskAsPlaceholder ? "" : DEFAULT_EMPTY_VALUE;

      if (!onChange) {
        return;
      }
      else if (value !== emptyValue && !Pet.isBirthdayValid(value)) {
        return;
      }

      const target = {
        name,
        value: value === emptyValue ? "" : value,
      } as HTMLInputElement;

      const event = { target } as React.ChangeEvent<HTMLInputElement>;
      onChange(event);
    };

    return (
      <IMaskInput
        {...other}
        mask="00/00"
        lazy={doNotShowMaskAsPlaceholder ? true : false}
        inputRef={ref}
        onAccept={handleAccept}
      />
    );
  }
);