import { Customer, CustomerType } from "./Customer";
import { DiscountData, DiscountType } from "./Discount";
import { UsedDiscountData } from "./UsedDiscount";

interface DiscountCodeData extends DiscountData {
    status: DiscountCodeStatus,
    created_at: Date,
    code: string,
    expiration_days: number,
    target?: DiscountCodeTarget
}

enum DiscountCodeTarget {
    customers = "customers",
    leads = "leads"
}

interface DiscountCode extends DiscountCodeData {
    id: string
}

class DiscountCode {
    constructor(id: string, data: DiscountCodeData) {
        this.id = id;
        Object.assign(this, data);
    }
    toData(): DiscountCodeData {
        const { id, ...data } = this;
        return data;
    }
    toDiscountData(): DiscountData {
        return {
            type: this.type,
            value: this.value
        };
    }
    canBeUsed() {
        return (
            this.status === DiscountCodeStatus.unused &&
            (new Date().getTime() - this.created_at.getTime()) / (1000 * 60 * 60 * 24) <= this.expiration_days
        );
    }
    isValidGlobalCode(customer: Customer) {
        const customerTarget =
            customer.type === CustomerType.lead
                ? DiscountCodeTarget.leads
                : DiscountCodeTarget.customers;

        const matchesTargetAndUsable =
            this.target === customerTarget && this.canBeUsed();

        return (
            this.type === DiscountType.global_recurrence
                ? matchesTargetAndUsable && !customer.isRecurrent()
                : matchesTargetAndUsable
        );
    }
    toUsedDiscountData(): UsedDiscountData {
        return ({
            code: this.code,
            applied_at: new Date(),
            value: this.value,
            type: this.type
        });
    }
    static generateRandomCode() {
        const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const numbers = "0123456789";
        let code = "";
        for (let i = 0; i < 4; i++) {
            code += letters.charAt(Math.floor(Math.random() * letters.length));
            code += numbers.charAt(Math.floor(Math.random() * numbers.length));
        }
        return code;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromApi(serialized: any) {
        const { id, ...data } = serialized;
        return new DiscountCode(id, data);
    }
}

enum DiscountCodeStatus {
    used = "used",
    unused = "unused"
}

export { DiscountCode, DiscountCodeStatus, DiscountCodeTarget };
export type { DiscountCodeData };