export { };

declare global {
    interface Array<T> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sortByFieldAscending(fieldExtractor: (item: T) => any): this;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sortByFieldDescending(fieldExtractor: (item: T) => any): this;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        findFirstByAscending(fieldExtractor: (item: T) => any): T | undefined;
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
Array.prototype.sortByFieldAscending = function <T>(fieldExtractor: (item: T) => any) {
    return this.sort((a, b) => {
        const valueA = fieldExtractor(a);
        const valueB = fieldExtractor(b);

        if (valueA < valueB) {
            return -1;
        } else if (valueA > valueB) {
            return 1;
        } else {
            return 0;
        }
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
Array.prototype.sortByFieldDescending = function <T>(fieldExtractor: (item: T) => any) {
    return this.sort((a, b) => {
        const valueA = fieldExtractor(a);
        const valueB = fieldExtractor(b);

        if (valueA > valueB) {
            return -1;
        } else if (valueA < valueB) {
            return 1;
        } else {
            return 0;
        }
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
Array.prototype.findFirstByAscending = function <T>(fieldExtractor: (item: T) => any) {
    return this.length
        ? this.reduce((a, b) => fieldExtractor(a) <= fieldExtractor(b) ? a : b)
        : undefined;
};