export enum CollectionPaths {
    AircallActivities = "aircall_activities",
    Appointments = "appointments",
    AutomatedMessages = "automated_messages",
    Breeds = "breeds",
    CompanyGoals = "company_goals",
    ConversationSessions = "conversation_sessions",
    Credits = "credits",
    CreditCards = "credit_cards",
    Customers = "customers",
    Configuration = "configuration",
    CsatScores = "csat_scores",
    DelayedMessages = "delayed_messages",
    Discounts = "discounts",
    DiscountCodes = "discount_codes",
    Hubs = "hubs",
    Messages = "messages",
    Notifications = "notifications",
    FederalHolidays = "federal_holidays",
    CustomOfficeClosures = "custom_office_closures",
    AiActivities = "ai_activities",
    AiPrompts = "ai_prompts",
    Groomers = "groomers",
    GroomerExceptions = "groomer_exceptions",
    MessageTemplates = "message_templates",
    PersonalEvents = "personal_events",
    Pets = "pets",
    PricingPresets = "pricing_presets",
    Recurrences = "recurrences",
    SecondaryContacts = "secondary_contacts",
    SecondaryAddresses = "secondary_addresses",
    Segments = "segments",
    Services = "services",
    Users = "users",
    UsedDiscounts = "used_discounts",
    Vans = "vans",
    Revisions = "revisions",
    VanExceptions = "van_exceptions",
    ErrorCodes = "error_codes",
    Insights = "insights",
    ReferralActivities = "referral_activities",
    RestrictedServiceGroups = "restricted_service_groups",
    DailyAnnouncements = "daily_announcements",
    Logs = "logs",
    DriveTimes = "drive_times",
    BroadcastMessageActivities = "broadcast_message_activities",
}