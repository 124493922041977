import { Hub } from "@marathon/common/entities/Hub";
import { TimeZone } from "@marathon/common/helpers/timeZoneHelper";
import loadGoogleMapsApi from "@marathon/web/loadGoogleMapsApi";

const DISTANCE_MATRIX_STATUS_OK = "OK";
const GOOGLE_MAPS_API_URL = "https://maps.googleapis.com/maps/api";

async function getDriveTimes(origins: google.maps.Place[], destinations: google.maps.Place[]): Promise<{ duration: google.maps.Duration; }[]> {
    await loadGoogleMapsApi();
    return new Promise((resolve, reject) => {
        const googleService = new window.google.maps.DistanceMatrixService();
        googleService.getDistanceMatrix(
            {
                origins,
                destinations,
                travelMode: google.maps.TravelMode.DRIVING
            },
            response => {
                if (!response)
                    throw new Error("Error calling googleService.getDistanceMatrix");
                const driveTimes = response.rows
                    .map(x => x.elements[0])
                    .filter(x => x.status === DISTANCE_MATRIX_STATUS_OK)
                    .map(x => ({ duration: x.duration }));
                if (driveTimes.length !== response.rows.length) {
                    reject(
                        new Error(
                            `Some distance matrix API comparisons failed for ${destinations
                                .map(x => x.placeId)
                                .join(",")}.`
                        )
                    );
                } else {
                    driveTimes.sortByFieldAscending(x => x.duration.value);
                    resolve(driveTimes);
                }
            }
        );
    });
}

export default class GoogleMapsApi {
    static async calculateRoute(hubPlaceId: string, customerPlaceIds: string[]) {
        await loadGoogleMapsApi();
        const directionsService = new window.google.maps.DirectionsService();
        return (
            await directionsService
                .route({
                    origin: { placeId: hubPlaceId },
                    destination: { placeId: hubPlaceId },
                    waypoints: customerPlaceIds.map(placeId => ({
                        location: { placeId },
                        stopover: true
                    })),
                    optimizeWaypoints: false,
                    travelMode: google.maps.TravelMode.DRIVING,
                })
        );
    }
    static async getTimeZoneFromLatAndLng(lat: number, lng: number): Promise<TimeZone> {
        const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
        if (!key)
            throw new Error("Invalid Google Maps API key");

        const response = await fetch(`${GOOGLE_MAPS_API_URL}/timezone/json?location=${lat},${lng}&timestamp=${Date.now() / 1000}&key=${key}`);
        if (!response.ok)
            throw new Error(`Failed to get timezone from Google Maps API: ${response.statusText}`);

        const result = await response.json();
        if (!Object.values(TimeZone).includes(result.timeZoneId))
            throw new Error(`${result.timeZoneId} time zone not supported`);

        return result.timeZoneId;
    }
    static async getLatAndLngFromZip(zip: string) {
        await loadGoogleMapsApi();
        const geocoder = new window.google.maps.Geocoder();
        return new Promise<{ lat: number, lng: number, bounds?: google.maps.LatLngBounds }>((resolve, reject) => {
            geocoder.geocode({ address: zip }, (results, status) => {
                if (status === "OK" && results) {
                    resolve({
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng(),
                        bounds: results[0].geometry.bounds
                    });
                } else {
                    reject(new Error(`Geocode was not successful for the following reason: ${status}`));
                }
            });
        });
    }
    static async getDriveTimeFromHub(hub: Hub, placeId: string) {
        const driveTimes = await getDriveTimes(
            [{ placeId: hub.place_id }],
            [{ placeId }]
        );
        return {
            hub_id: hub.id,
            duration_text: driveTimes[0].duration.text,
            duration_value: driveTimes[0].duration.value
        };
    }
}