import { shouldBeUnreachable } from "../helpers/typesHelper";
import { MobileServiceFeeConfiguration } from "./Configuration";
import { Customer } from "./Customer";
import { DiscountCode } from "./DiscountCode";
import { Hub } from "./Hub";

interface DiscountData {
    type: DiscountType,
    value: number
}

interface Discount extends DiscountData {
    id: string
}

interface GetFirstOccurrenceDiscountInput {
    customer: Customer;
    hubs: Hub[];
    discounts: Discount[];
    interval?: number;
    invitationCode?: string;
}

interface GetFirstTimeCustomerDiscountInput {
    customer: Customer;
    hubs: Hub[];
    interval?: number;
    invitationCode?: string;
}

const QUALIFYING_RECURRENCE_INTERVALS = [1, 2, 3, 4];
const REFERRAL_DISCOUNT_VALUE = 40;
const REFERRAL_CREDIT_VALUE = 40;
const FIRST_TIME_CUSTOMER_EXTRA_DISCOUNT = 10;

class Discount {
    constructor(id: string, data: DiscountData) {
        this.id = id;
        Object.assign(this, data);
    }
    toData(): DiscountData {
        const { id, ...data } = this;
        return data;
    }
    static getInternalDescription(type: DiscountType) {
        switch (type) {
            case DiscountType.firstOccurrence:
                return "First groom";
            case DiscountType.referral:
                return "Referral";
            case DiscountType.manual:
                return "Manual discount";
            case DiscountType.firstTimeCustomer:
                return "First time customer";
            case DiscountType.mobileServiceFee:
                return "Mobile service fee";
            case DiscountType.emailCode:
                return "Promo code";
            case DiscountType.partnerships:
                return "Partnerships";
            case DiscountType.recurringCustomer:
                return "Recurring customer";
            case DiscountType.groomingServiceRecovery:
                return "Grooming service recovery";
            case DiscountType.schedulingServiceRecovery:
                return "Scheduling service recovery";
            case DiscountType.gbOther:
                return "GB other";
            case DiscountType.callOutServiceRecovery:
                return "Call-out service recovery";
            case DiscountType.rescuePartnership:
                return "Rescue/Community partnership";
            case DiscountType.b2bPartnership:
                return "B2B partnership";
            case DiscountType.global:
                return "Promo discount";
            case DiscountType.global_recurrence:
                return "Promo recurring discount";
            default:
                shouldBeUnreachable(type);
        }
    }
    static getPublicFacingDescription(type: DiscountType) {
        switch (type) {
            case DiscountType.firstOccurrence:
                return "First groom discount";
            case DiscountType.referral:
                return "First appointment discount";
            case DiscountType.manual:
                return "Special discount";
            case DiscountType.firstTimeCustomer:
                return "First time customer discount";
            case DiscountType.mobileServiceFee:
                return "Waived mobile service fee";
            case DiscountType.emailCode:
            case DiscountType.partnerships:
                return "Discount code";
            case DiscountType.recurringCustomer:
                return "Recurring customer discount";
            case DiscountType.groomingServiceRecovery:
                return "Grooming service recovery discount";
            case DiscountType.schedulingServiceRecovery:
                return "Scheduling service recovery discount";
            case DiscountType.gbOther:
                return "Other";
            case DiscountType.callOutServiceRecovery:
                return "Call-out service recovery discount";
            case DiscountType.rescuePartnership:
                return "Rescue/Community partnership discount";
            case DiscountType.b2bPartnership:
                return "B2B partnership discount";
            case DiscountType.global:
                return "Promo discount";
            case DiscountType.global_recurrence:
                return "Promo recurring discount";
            default:
                shouldBeUnreachable(type);
        }
    }
    static getTypeText = (type: DiscountType) => {
        switch (type) {
            case DiscountType.firstOccurrence:
                return "First groom";
            case DiscountType.referral:
                return "Referral";
            case DiscountType.firstTimeCustomer:
                return "First time customer";
            default:
                console.warn("Discount type not found, using enum value as description instead", type);
                return type;
        }
    };
    static apply(discountValues: DiscountData[], originalPrice: number) {
        const discountTotal = discountValues.reduce((partialSum, a) => +partialSum + a.value, 0);
        return originalPrice - discountTotal;
    }
    static qualifiesForRecurrenceDiscount = (interval?: number) => {
        if (!interval) {
            return false;
        }
        else {
            return QUALIFYING_RECURRENCE_INTERVALS.includes(interval);
        }
    }
    private static getDiscountByType(discounts: Discount[], type: DiscountType): DiscountData | undefined {
        return discounts.find(x => x.type === type)?.toData();
    }
    static getReferralDiscount(discounts: Discount[]): DiscountData | undefined {
        return this.getDiscountByType(discounts, DiscountType.referral);
    }
    static getMobileServiceFeeDiscount(
        customer: Customer,
        interval: number,
        mobileServiceFee: MobileServiceFeeConfiguration,
        discounts: Discount[]
    ): DiscountData | undefined {
        return (
            customer.qualifiesFor.mobileServiceFeeDiscount(mobileServiceFee) &&
                Discount.qualifiesForRecurrenceDiscount(interval)
                ? this.getDiscountByType(discounts, DiscountType.mobileServiceFee)
                : undefined
        );
    }
    static getFirstOccurrenceDiscount(input: GetFirstOccurrenceDiscountInput): DiscountData | undefined {
        const { customer, hubs, discounts, interval, invitationCode } = input;
        if (!customer.qualifiesFor.firstOccurrenceDiscount(hubs, invitationCode))
            return undefined;

        const firstOccurrenceDiscount = this.getDiscountByType(discounts, DiscountType.firstOccurrence);
        const isQualifyingRecurrence = Discount.qualifiesForRecurrenceDiscount(interval);

        return (
            isQualifyingRecurrence && firstOccurrenceDiscount
                ? firstOccurrenceDiscount
                : undefined
        );
    }
    static getFirstTimeCustomerDiscount(input: GetFirstTimeCustomerDiscountInput): DiscountData | undefined {
        const { customer, hubs, interval, invitationCode } = input;
        if (!customer.qualifiesFor.firstTimeCustomerDiscount(hubs, invitationCode))
            return undefined;

        const customerHub = hubs.find(x => x.id === customer.address.drive_time?.hub_id);
        const discountConfiguration = customerHub?.first_time_customer_discount;
        if (!discountConfiguration?.is_enabled)
            return undefined;

        const discount = {
            type: DiscountType.firstTimeCustomer,
            value: discountConfiguration.value
        };

        if (interval && discount && Discount.qualifiesForRecurrenceDiscount(interval)) {
            discount.value = discount.value + FIRST_TIME_CUSTOMER_EXTRA_DISCOUNT;
        }

        return discount;
    }
    static getAppointmentDiscounts(
        customer: Customer,
        isFromOnlineBooking: boolean,
        hubs: Hub[],
        discounts: Discount[],
        mobileServiceFee: MobileServiceFeeConfiguration,
        interval?: number,
        invitationCode?: string,
        discountCode?: DiscountCode
    ): DiscountData[] {
        if (discountCode) {
            return (
                discountCode.type === DiscountType.global_recurrence && !Discount.qualifiesForRecurrenceDiscount(interval)
                    ? []
                    : [discountCode.toDiscountData()]
            );
        } else {
            const appointmentDiscounts: DiscountData[] = [];

            const mobileServiceFeeDiscount = interval ? this.getMobileServiceFeeDiscount(customer, interval, mobileServiceFee, discounts) : undefined;
            mobileServiceFeeDiscount && appointmentDiscounts.push(mobileServiceFeeDiscount);

            if (isFromOnlineBooking) {
                if (invitationCode) {
                    const referralDiscount = this.getReferralDiscount(discounts);
                    referralDiscount && appointmentDiscounts.push(referralDiscount);
                }

                const firstOccurrenceDiscount = interval ? this.getFirstOccurrenceDiscount({ customer, hubs, discounts, interval, invitationCode }) : undefined;
                firstOccurrenceDiscount && appointmentDiscounts.push(firstOccurrenceDiscount);

                const firstTimeCustomerDiscount = this.getFirstTimeCustomerDiscount({ customer, hubs, interval, invitationCode });
                firstTimeCustomerDiscount && appointmentDiscounts.push(firstTimeCustomerDiscount);
            }

            return appointmentDiscounts;
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromApi(type: any) {
        const { id, ...data } = type;
        return new Discount(id, data);
    }
    static get customAdminsAppDiscountOptions() {
        const validDiscounts = [
            DiscountType.firstTimeCustomer,
            DiscountType.recurringCustomer,
            DiscountType.groomingServiceRecovery,
            DiscountType.schedulingServiceRecovery,
            DiscountType.callOutServiceRecovery,
            DiscountType.partnerships
        ];
        return validDiscounts.map((x) => ({
            type: x,
            label: Discount.getInternalDescription(x)
        }));
    }
    static get types() {
        return {
            global: [
                DiscountType.global,
                DiscountType.global_recurrence
            ],
            onlyForFirstOccurrence: [
                DiscountType.firstOccurrence,
                DiscountType.referral,
                DiscountType.firstTimeCustomer,
                DiscountType.global
            ]
        };
    }
}

enum DiscountType {
    //HACK: We renamed firstGroom to firstOccurrence for clarity, but we need to keep the old value for backwards compatibility.
    firstOccurrence = "first_groom",
    mobileServiceFee = "mobile_service_fee",
    referral = "referral",
    manual = "manual",
    firstTimeCustomer = "first_time_customer",
    emailCode = "code",
    partnerships = "partnerships",
    recurringCustomer = "recurring_customer",
    groomingServiceRecovery = "grooming_service_recovery",
    schedulingServiceRecovery = "scheduling_service_recovery",
    gbOther = "GB other",
    callOutServiceRecovery = "call_out_service_recovery",
    rescuePartnership = "rescue_partnership",
    b2bPartnership = "b2b_partnership",
    global = "global",
    global_recurrence = "global_recurrence"
}

export { Discount, DiscountType, REFERRAL_DISCOUNT_VALUE, REFERRAL_CREDIT_VALUE, FIRST_TIME_CUSTOMER_EXTRA_DISCOUNT };
export type { DiscountData };
