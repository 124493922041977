import { Paths } from "../utilities/TypeUtils";

interface QueryFilter<T, K extends Paths<T> = Paths<T>> {
    field: K;
    operator: FilterOperator;
    value: unknown;
}

enum FilterOperator {
    in = "in",
    greaterThanOrEqual = ">=",
    lessThanOrEqual = "<=",
    greaterThan = ">",
    lessThan = "<",
    equal = "==",
    notEqual = "!=",
    arrayContains = "array-contains",
    arrayContainsAny = "array-contains-any",
}

enum OrderDirection {
    asc = "asc",
    desc = "desc"
}

interface OrderBy<T> {
    field: Paths<T>;
    direction: "asc" | "desc";
}

export function getFilter<T, K extends Paths<T> = Paths<T>>(
    field: K,
    operator: FilterOperator,
    value: unknown
): QueryFilter<T, K> {
    return { field, operator, value };
}

export function getOrderBy<T>(field: Paths<T>, direction: "asc" | "desc"): OrderBy<T> {
    return { field, direction };
}

export { FilterOperator, OrderDirection };
export type { QueryFilter, OrderBy };