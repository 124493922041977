import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { CustomerAddressData } from "@marathon/common/entities/Customer";
import { Hub } from "@marathon/common/entities/Hub";
import { GoogleAddress } from "@marathon/common/api/GooglePlace";
import { Coordinates, isWithinServiceAreaForHub } from "@marathon/common/helpers/mapHelper";
import loadGoogleMapsApi from "@marathon/web/loadGoogleMapsApi";
import GoogleMapsApi from "./GoogleMapsApi";

interface GoogleAddressValue {
    terms: { value: string }[]
    place_id?: string
}

function getRawAddress(value: GoogleAddressValue) {
    return value.terms.slice(0, -1).map(x => x.value).join(" ");
}

enum AddressType {
    street_number = "street_number",
    route = "route",
    locality = "locality",
    administrative_area_level_1 = "administrative_area_level_1",
    postal_code = "postal_code",
    country = "country",
    neighborhood = "neighborhood"
}

//TODO: Solve the type incompatibility (between web and node SDKs) and unify with the getAddressFromTextInput function in packages/common/addressHelper.ts
function getAddressFromGeocoderResult(geocoderResult: google.maps.GeocoderResult) {
    const address: GoogleAddress = {
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: ""
    };
    geocoderResult.address_components.forEach(x => {
        if (x.types.includes(AddressType.street_number))
            address.address1 = x.long_name;
        else if (x.types.includes(AddressType.route)) {
            address.address1 += ` ${x.long_name}`;
            address.street_name = x.short_name;
        }
        else if (x.types.includes(AddressType.locality))
            address.city = x.long_name;
        else if (x.types.includes(AddressType.administrative_area_level_1))
            address.state = x.short_name;
        else if (x.types.includes(AddressType.postal_code))
            address.zip = x.long_name;
        else if (x.types.includes(AddressType.country))
            address.country = x.short_name;
        else if (x.types.includes(AddressType.neighborhood))
            address.area = x.long_name;
    });
    return address;
}

export default class GooglePlaces {
    static async getCustomerAddressByGoogleAutocomplete(selected: { value: GoogleAddressValue }, hubs: Hub[]): Promise<CustomerAddressData> {
        const address: CustomerAddressData = {
            address1: getRawAddress(selected.value),
            address2: "",
            city: "",
            state: "",
            country: "",
            place_id: selected.value.place_id
        };

        if (!selected.value.place_id) {
            return address;
        }
        else {
            const googleAddress = await GooglePlaces.getAddressByGoogleAutocomplete(selected);
            const coordinates = {
                lat: googleAddress.lat,
                lng: googleAddress.lng
            };
            //HACK: This assumes there are no overlapping service areas
            const hub = coordinates.lat && coordinates.lng ? hubs.find(x => isWithinServiceAreaForHub(coordinates as Coordinates, x)) : undefined;

            return {
                ...googleAddress,
                ...coordinates,
                place_id: googleAddress.place_id,
                drive_time: hub
                    ? await GoogleMapsApi.getDriveTimeFromHub(hub, selected.value.place_id)
                    : undefined
            };
        }
    }
    static async getAddressByGoogleAutocomplete(selected: { value: GoogleAddressValue }): Promise<GoogleAddress> {
        const address: GoogleAddress = {
            address1: getRawAddress(selected.value),
            address2: "",
            city: "",
            state: "",
            country: "",
            place_id: selected.value.place_id
        };

        if (!selected.value.place_id) {
            return address;
        }
        else {
            const geocoderResults = await geocodeByPlaceId(selected.value.place_id);
            if (geocoderResults.length === 0)
                return address;

            const geocoderResult = geocoderResults[0];
            const geocodedAddress = getAddressFromGeocoderResult(geocoderResult);
            const coordinates = {
                lat: geocoderResult.geometry.location.lat(),
                lng: geocoderResult.geometry.location.lng()
            };

            return {
                ...geocodedAddress,
                ...coordinates,
                place_id: geocoderResult.place_id
            };
        }
    }
    static async getGoogleAutocompleteOption(placeId: string) {
        await loadGoogleMapsApi();
        const results = await geocodeByPlaceId(placeId);
        if (results && results[0] && results[0].formatted_address) {
            return {
                label: results[0].formatted_address,
                value: placeId,
            };
        }
        return null;
    }
}

export type { GoogleAddressValue };