import { forwardRef } from "react";
import { IMaskInput } from "react-imask";
import { InputBaseComponentProps } from "@mui/material/InputBase";

interface PhoneMaskedInputProps extends InputBaseComponentProps {
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  doNotShowMaskAsPlaceholder?: true;
}

export const PhoneMaskedInput = forwardRef<HTMLInputElement, PhoneMaskedInputProps>(
  function PhoneMaskedInput({ name, onChange, doNotShowMaskAsPlaceholder, ...other }, ref) {
    const handleAccept = (value: string) => {
      if (!onChange) {
        return;
      }

      const target = {
        name,
        value,
      } as HTMLInputElement;

      const event = { target } as React.ChangeEvent<HTMLInputElement>;
      onChange(event);
    };

    return (
      <IMaskInput
        {...other}
        mask="(000) 000-0000"
        placeholderChar="#"
        lazy={doNotShowMaskAsPlaceholder ? true : false}
        unmask={true}
        inputRef={ref}
        onAccept={handleAccept}
      />
    );
  }
);