export default class PhoneNumbers {
    private static cleanPhoneNumber(phone: string) {
        return phone.replace(/\D/g, "");
    }
    static getInternationalPhone(phone: string) {
        const cleaned = PhoneNumbers.cleanPhoneNumber(phone);
        if (cleaned.length === 10) {
            return `+1${cleaned}`;
        }
        else if (cleaned.startsWith("1") && cleaned.length === 11) {
            return `+${cleaned}`;
        }
        else if (phone.startsWith("+")) {
            return phone;
        }

        throw new Error("Invalid phone number");
    }
    static isValidNormalizedPhone(phone: string) {
        if (!phone)
            return false;

        return PhoneNumbers.cleanPhoneNumber(phone).length === 10;
    }
    static formatPhoneWithMask(input: string) {
        const digitsOnly: string = input.replace(/\D/g, "");
        const phoneMask: (string | RegExp)[] = [
            "(",
            /[1-9]/,
            /\d/,
            /\d/,
            ")",
            " ",
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
        ];

        let digitIndex = 0;
        let formattedString = "";
        for (const element of phoneMask) {
            if (typeof element === "string") {
                formattedString += element;
            } else if (element instanceof RegExp) {
                const match = digitsOnly.slice(digitIndex).match(element);
                if (match) {
                    formattedString += match[0];
                    digitIndex += match[0].length;
                }
            }
        }

        return formattedString;
    }
}
