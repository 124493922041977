import { DateTime } from "luxon";
import { calculatePetAge, getDurationTextFromMinutes } from "@marathon/common/helpers/timeHelper";
import { PricingPreset } from "./PricingPreset";
import { Breed } from "./Breed";
import { Service } from "./Service";
import { shouldBeUnreachable } from "../helpers/typesHelper";
import { TimeZone } from "../helpers/timeZoneHelper";
import LocalDate from "../utilities/LocalDate";

export const PUP_AGE_RANGES = [
    { from: 0, to: 3, avr_in_years: 0.125, text: "Under 3 Months" },
    { from: 3, to: 6, avr_in_years: 0.375, text: "3-6 Months" },
    { from: 6, to: 12, avr_in_years: 0.75, text: "6 Months - 1 year" }
];

interface PetData {
    name: string,
    //HACK: This redundant field is used to optimize the search by multiple customers
    customer_id: string,
    breed_id?: string,
    weight: number,
    notes: string,
    service_id?: string,
    price?: number,
    gender?: string,
    age_date?: Date,
    created_at: Date,
    status: PetStatus,
    is_deceased?: boolean,
    deceased_at?: Date,
    services: PetService[],
    profile_photo_url?: string,
    first_during_image_url?: string,
    latest_during_image_url?: string,
    latest_after_image_url?: string,
    do_not_book?: true,
    from_groombuggy?: true,
    salesforce_asset_id?: string,
    birthday?: string
}

interface PetService {
    id: string,
    price: number
}

interface Pet extends PetData {
    id: string,
    customerId: string
}

class Pet {
    constructor(id: string, customerId: string, data: PetData) {
        this.id = id;
        this.customerId = customerId;
        Object.assign(this, data);
    }
    getAge(atDate?: Date) {
        return (this.age_date
            ? calculatePetAge(this.age_date, atDate)
            : null
        );
    }
    isDefaultDog() {
        return this.breed_id === undefined;
    }
    isActive() {
        return this.status === PetStatus.active;
    }
    getDefaultServiceId() {
        const defaultService = this.getDefaultService();
        if (!defaultService)
            throw new Error(`Default service is required at this point, pet's path: /${this.customerId}/${this.id}`);

        return defaultService.id;
    }
    getDefaultService() {
        return this.services.sortByFieldDescending(x => x.price).at(0);
    }
    getService(serviceId: string, pricingPreset: PricingPreset) {
        const petService = this.services.find(x => x.id === serviceId);
        return petService ?? Pet.toServiceInput(serviceId, pricingPreset);
    }
    getServices(pricingPreset: PricingPreset, breeds: Breed[]) {
        const breed = breeds.find(b => b.id === this.breed_id);
        const servicesIds = breed?.getServiceIdsByWeight(this.weight);
        return servicesIds?.map(serviceId => Pet.toServiceInput(serviceId, pricingPreset));
    }
    getServicesDescriptionForChatbotPrompt(services: Service[], pricingPreset: PricingPreset) {
        const getFormattedService = (id: string, label: string, estimatedTime: number, price?: number) => {
            return `{ serviceId: ${id}, serviceLabel: ${label}, serviceEstimatedTime: ${getDurationTextFromMinutes(estimatedTime)}, servicePrice: ${price ?? "Unknown"} }`;
        };

        const chatbotServices = services.filter(x => !x.tied_breed && x.offer_in_chatbot).map(service => {
            const serviceFromPreset = pricingPreset.services.find(x => x.service_id === service.id);
            return getFormattedService(service.id, service.name, service.estimated_time, serviceFromPreset?.price);
        });

        const petServices = this.services.map(x => {
            const service = services.find(s => s.id === x.id);
            if (!service)
                throw new Error(`Service ${x.id} not found`);

            return getFormattedService(x.id, service.description, service.estimated_time, x.price);
        });
        return [...petServices, ...chatbotServices];
    }
    toData(): PetData {
        const { id, customerId, ...data } = this;
        return data;
    }
    hasGrown(sinceDate: Date) {
        const ageNow = this.getAge();
        const ageThen = this.getAge(sinceDate);
        return Boolean(ageNow && ageThen && ageNow > ageThen);
    }
    static filter(pet: Pet, filter: PetFilter) {
        switch (filter) {
            case PetFilter.validForBooking:
                return (pet.status == PetStatus.active && !pet.do_not_book && !!pet.breed_id);
            case PetFilter.nonDefault:
                return (pet.status == PetStatus.active && !!pet.breed_id);
            case PetFilter.active:
                return (pet.status == PetStatus.active);
            case PetFilter.all:
                return true;
            default:
                shouldBeUnreachable(filter);
        }
    }
    static getPetNames = (pets: Pet[], emptyLabel = "your pet") => {
        const names = pets.map(x => x.name);
        const finalName = names.pop();
        if (!finalName) {
            return emptyLabel;
        }
        else {
            return (
                names.length
                    ? `${names.join(", ")} and ${finalName}`
                    : finalName
            );
        }
    };
    static getBreedName(breedId: string | undefined, breeds: Breed[]) {
        const breed = breedId ? breeds.find(x => x.id === breedId) : null;
        return (breed ? breed.name : "Default Breed");
    }
    static areChangesRelevantToKlaviyo(before: PetData | undefined, after: PetData) {
        if (!before)
            return true;

        return (
            before.name !== after.name ||
            before.breed_id !== after.breed_id ||
            before.latest_during_image_url !== after.latest_during_image_url ||
            before.latest_after_image_url !== after.latest_after_image_url ||
            before.first_during_image_url !== after.first_during_image_url
        );
    }
    static isBirthdayValid(birthday: string) {
        if (!/^\d{2}\/\d{2}$/.test(birthday))
            return false;

        const parsed = DateTime.fromFormat(birthday, "MM/dd");
        return parsed.isValid;
    }
    static getBirthdayThisYear(birthday: string, year: number, timeZone: TimeZone): LocalDate {
        const [month, day] = birthday.split("/").map(Number);
        return new LocalDate(month, day, year, timeZone);
    }
    shouldShowBirthdayNotification(timeZone: TimeZone) {
        if (!this.birthday || !Pet.isBirthdayValid(this.birthday))
            return false;

        const today = LocalDate.forContextTimeZone(new Date(), timeZone);
        const birthdayThisYear = Pet.getBirthdayThisYear(this.birthday, today.year, timeZone);

        const start = today.getPlusDays(-7);
        const end = today.getPlusDays(7);

        return (
            birthdayThisYear.precedesOrEqual(end) &&
            start.precedesOrEqual(birthdayThisYear)
        );
    }
    getBirthdayMessage(timeZone: TimeZone) {
        if (!this.birthday)
            throw new Error("Birthday is required at this point");

        const today = LocalDate.forContextTimeZone(new Date(), timeZone);
        const birthdate = Pet.getBirthdayThisYear(this.birthday, today.year, timeZone);
        const difference = birthdate.daysBetween(today);

        const formattedDate = DateTime
            .fromObject({ year: birthdate.year, month: birthdate.month, day: birthdate.day }, { zone: birthdate.contextTimeZone })
            .toFormat("LLLL d");

        if (difference === 0) {
            return `${this.name}'s birthday is today!`;
        } else if (difference > 0) {
            return `${this.name}'s birthday is ${formattedDate}. That is in ${difference} day${difference > 1 ? "s" : ""}.`;
        } else {
            const abs = Math.abs(difference);
            return `${this.name}'s birthday was ${formattedDate}. That was ${abs} day${abs > 1 ? "s" : ""} ago.`;
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromApi(serialized: any) {
        const { id, customerId, ...data } = serialized;
        return new Pet(id, customerId, data);
    }
    static toServiceInput(serviceId: string, pricingPreset: PricingPreset): PetService {
        const servicePreset = pricingPreset.services.find(x => x.service_id === serviceId);
        if (!servicePreset)
            throw new Error("ServicePreset is required at this point");

        return ({
            id: serviceId,
            price: servicePreset.price
        });
    }
}

enum PetStatus {
    active = "active",
    deleted = "deleted"
}

enum PetFilter {
    validForBooking = "validForBooking",
    //HACK: This filter includes the do_not_book pets
    nonDefault = "nonDefault",
    active = "active",
    all = "all"
}

export { Pet, PetStatus, PetFilter };
export type { PetData, PetService };
