import React, { useState } from "react";
import { Button } from "@marathon/web/components/Button";
import { ConditionalWrapper } from "@marathon/web/components/ConditionalWrapper";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { AppointmentInfoHeader } from "@marathon/web/components/appointment/AppointmentInfoHeader";
import { Box, MenuItem, Radio, Select, Typography, SelectChangeEvent } from "@mui/material";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { BookingSuggestion } from "@marathon/common/api/BookingSuggestion";
import { LeadOutcome } from "@marathon/common/entities/Customer";
import * as Sentry from "@sentry/react";
import { Discount, DiscountData } from "@marathon/common/entities/Discount";
import { WEEKLY_FREQUENCY_TYPE } from "@marathon/common/entities/Recurrence";
import { TitleWrapper, ContentWrapper } from "./CommonWrappers";
import { Hub } from "@marathon/common/entities/Hub";
import { MobileServiceFeeConfiguration } from "@marathon/common/entities/Configuration";
import { DiscountCard } from "./DiscountCard";
import { DiscountCode, DiscountCodeData } from "@marathon/common/entities/DiscountCode";
import { Customer } from "@marathon/common/entities/Customer";

interface AppointmentOptionsProps {
    customer: Customer,
    suggestion: BookingSuggestion,
    onConfirmOptions: () => void,
    tryUpdateLeadInfoOutcome?: (initialStatus: LeadOutcome, finalStatus: LeadOutcome) => Promise<void>,
    handleFrequency: (f: { interval: number, type: string }) => void,
    frequency: { interval: number, type: string },
    appointmentType: typeof ONE_TIME_TYPE | typeof RECURRENT_TYPE,
    handleChangeAppointmentType: (type: typeof ONE_TIME_TYPE | typeof RECURRENT_TYPE) => void,
    discounts: Discount[],
    hubs: Hub[],
    mobileServiceFee: MobileServiceFeeConfiguration,
    invitationCode?: string,
    discountCode?: DiscountCode,
    isTrackingStep?: boolean
}

export const ONE_TIME_TYPE = "oneTime";
export const RECURRENT_TYPE = "recurrent";

export default function AppointmentOptions({
    customer,
    suggestion,
    onConfirmOptions,
    tryUpdateLeadInfoOutcome,
    handleFrequency,
    frequency,
    appointmentType,
    handleChangeAppointmentType,
    discounts,
    hubs,
    mobileServiceFee,
    invitationCode,
    discountCode,
    isTrackingStep }: AppointmentOptionsProps) {

    const [isConfirmingOptions, setIsConfirmingOptions] = useState(false);
    const classes = useStyles();

    const firstOccurrenceDiscount = Discount.getFirstOccurrenceDiscount({ customer, hubs, discounts, interval: frequency.interval, invitationCode });
    const firstTimeCustomerDiscountOneTime = Discount.getFirstTimeCustomerDiscount({ customer, hubs, invitationCode });
    const firstTimeCustomerDiscountRegular = Discount.getFirstTimeCustomerDiscount({ customer, hubs, interval: frequency.interval, invitationCode });
    const mobileServiceFeeDiscount = Discount.getMobileServiceFeeDiscount(customer, frequency.interval, mobileServiceFee, discounts);

    const handleConfirmOptions = async () => {
        try {
            setIsConfirmingOptions(true);
            await tryUpdateLeadInfoOutcome?.(LeadOutcome.open_appointment_options, LeadOutcome.open_confirmation);

            onConfirmOptions();
        }
        catch (error) {
            Sentry.captureException(error);
        }
        finally {
            setIsConfirmingOptions(false);
        }
    };

    return (
        <OnlineBookingContentWrapper>
            <TitleWrapper title="Appointment options" />
            <ContentWrapper
                sx={{
                    fontSize: "16px",
                    lineHeight: "1.5em",
                    textAlign: "left"
                }}>
                {discountCode && <DiscountCard discount={discountCode} />}
                <AppointmentInfoHeader
                    suggestion={suggestion}
                    isFromOnlineBooking={true}
                    variant="secondary"
                />
                <div style={{ marginTop: 20 }}>
                    <OptionWrapper
                        label="Make it regular"
                        checked={appointmentType === RECURRENT_TYPE}
                        onChange={() => handleChangeAppointmentType(RECURRENT_TYPE)}
                        onSelectChange={(v) => {
                            handleFrequency({
                                interval: parseInt(v),
                                type: WEEKLY_FREQUENCY_TYPE
                            });
                        }}
                        value={frequency.interval.toString()}
                        options={[
                            { label: "Every 1 week", value: "1" },
                            { label: "Every 2 weeks", value: "2" },
                            { label: "Every 4 weeks", value: "4" }
                        ]}
                        firstOccurrenceDiscount={firstOccurrenceDiscount}
                        firstTimeCustomerDiscount={firstTimeCustomerDiscountRegular}
                        mobileServiceFeeDiscount={mobileServiceFeeDiscount}
                        discountCode={discountCode}
                    />
                </div>
                <div style={{ marginTop: 20 }}>
                    <OptionWrapper
                        label="One-time appointment"
                        checked={appointmentType === ONE_TIME_TYPE}
                        firstTimeCustomerDiscount={firstTimeCustomerDiscountOneTime}
                        onChange={() => handleChangeAppointmentType(ONE_TIME_TYPE)}
                    />
                </div>
                <Button
                    showSpinner={isConfirmingOptions || isTrackingStep}
                    variant="contained"
                    className={classes.button}
                    onClick={handleConfirmOptions}
                >
                    Continue
                </Button>
            </ContentWrapper>
        </OnlineBookingContentWrapper>
    );
}

interface OptionWrapperProps {
    label: string,
    checked: boolean,
    onChange: () => void,
    firstOccurrenceDiscount?: DiscountData,
    mobileServiceFeeDiscount?: DiscountData,
    firstTimeCustomerDiscount?: DiscountData,
    discountCode?: DiscountCodeData,
    value?: string,
    onSelectChange?: (value: string) => void,
    options?: { label: string, value: string }[]
}

const OptionWrapper = ({
    label,
    checked,
    onChange,
    value,
    onSelectChange,
    options,
    firstOccurrenceDiscount,
    mobileServiceFeeDiscount,
    firstTimeCustomerDiscount,
    discountCode
}: OptionWrapperProps) => {
    return (
        <ConditionalWrapper
            condition={!discountCode && (!!mobileServiceFeeDiscount || !!firstOccurrenceDiscount || !!firstTimeCustomerDiscount)}
            wrapper={children =>
                <DiscountWrapper
                    mobileServiceFeeDiscount={mobileServiceFeeDiscount}
                    firstOccurrenceDiscount={firstOccurrenceDiscount}
                    firstTimeCustomerDiscount={firstTimeCustomerDiscount}
                    checked={checked}
                >
                    {children}
                </DiscountWrapper>
            }
        >
            <Box
                margin="auto"
                px="5px"
                width={{ xs: "100%", sm: "600px" }}
                height={{ xs: "70px" }}
                sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    borderRadius: "6px",
                    border: checked ? "2px solid #0066FF" : "1px solid #D7D7D7",
                    cursor: "pointer",
                    backgroundColor: "#fff",
                    color: checked ? "#191919" : "#737373"
                }}
                onClick={onChange}
            >
                <Radio checked={checked} />
                <Typography>{label}</Typography>
                {options?.length &&
                    onSelectChange &&
                    <Select
                        size="small"
                        sx={{
                            marginLeft: "auto",
                            marginRight: "9px",
                            color: "#0066FF",
                            fontSize: "14px"
                        }}
                        value={value}
                        onChange={(e: SelectChangeEvent<string>) => onSelectChange(e.target.value)}
                    >
                        {options.map((o, idx) => <MenuItem key={idx} value={o.value}>{o.label}</MenuItem>)}
                    </Select>
                }
            </Box>
        </ConditionalWrapper>
    );
};

interface IDiscountWrapper {
    checked: boolean,
    children: React.ReactNode,
    mobileServiceFeeDiscount?: DiscountData,
    firstOccurrenceDiscount?: DiscountData,
    firstTimeCustomerDiscount?: DiscountData
}

const DiscountWrapper: React.FC<IDiscountWrapper> = ({
    mobileServiceFeeDiscount,
    firstOccurrenceDiscount,
    firstTimeCustomerDiscount,
    checked,
    children
}: IDiscountWrapper) => {
    return (
        <Box
            width={{ xs: "100%", sm: "fit-content" }}
            bgcolor={checked ? "#DE5AFF" : "#E2E2E2"}
            borderRadius={"10px"}
        >
            <Typography
                style={{ fontSize: 12 }}
                textAlign="start"
                fontFamily="Plain Bold"
                padding="9px 0px 7px 14px"
                color="#fff"
                fontWeight="bold">
                {getDiscountText(mobileServiceFeeDiscount, firstOccurrenceDiscount, firstTimeCustomerDiscount)}
            </Typography>
            {children}
        </Box>
    );
};

const getDiscountText = (mobileServiceFeeDiscount?: DiscountData, firstOccurrenceDiscount?: DiscountData, firstTimeCustomerDiscount?: DiscountData) => {
    let discountTexts = [];

    if (firstOccurrenceDiscount) {
        discountTexts.push(`Special: $${firstOccurrenceDiscount.value} off first groom`);
        if (mobileServiceFeeDiscount) {
            discountTexts.push(`AND $${mobileServiceFeeDiscount.value} off each Mobile Service Fee`);
        }
    }

    if (firstTimeCustomerDiscount) {
        discountTexts.push(`Special: $${firstTimeCustomerDiscount.value} off first groom`);
        if (mobileServiceFeeDiscount) {
            discountTexts.push(`AND $${mobileServiceFeeDiscount.value} off each Mobile Service Fee`);
        }
    }

    if (mobileServiceFeeDiscount && !firstOccurrenceDiscount && !firstTimeCustomerDiscount) {
        discountTexts.push(`Special: $${mobileServiceFeeDiscount.value} off each Mobile Service Fee`);
    }

    return discountTexts.map((text, index) => (
        <React.Fragment key={index}>
            {text}{index < discountTexts.length - 1 ? <br /> : null}
        </React.Fragment>
    ));
};
