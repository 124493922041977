import { UsedDiscount, UsedDiscountData } from "@marathon/common/entities/UsedDiscount";
import { container, inject, singleton } from "tsyringe";
import { DocResult, INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { IFirestoreService } from "./IFirestoreService";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";
import { FilterOperator, getFilter } from "@marathon/common/api/QueryFilter";

const mapEntity = function (snapshot: DocResult<UsedDiscountData>) {
    if (!snapshot.parentId)
        throw new Error("Parent id is required");
    return new UsedDiscount(snapshot.id, snapshot.parentId, snapshot.data);
};

@singleton()
export class UsedDiscountRepository {
    private firestoreService: IFirestoreService<UsedDiscountData>;
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<UsedDiscountData>) {
        injectedService.parentCollectionPath = CollectionPaths.Customers;
        injectedService.collectionPath = CollectionPaths.UsedDiscounts;
        this.firestoreService = injectedService;
    }
    static get current() {
        return container.resolve(UsedDiscountRepository);
    }
    async create(customerId: string, data: UsedDiscountData) {
        const docId = await this.firestoreService.create(data, customerId);
        return mapEntity({ id: docId, parentId: customerId, data });
    }
    async hasBeenUsed(customerId: string, discountCode: string) {
        const docs = await this.firestoreService.search({
            filters: [
                getFilter("code", FilterOperator.equal, discountCode)
            ]
        }, customerId);
        return docs.length > 0;
    }
}