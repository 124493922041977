import { Discount, DiscountType } from "@marathon/common/entities/Discount";
import { Box, Typography } from "@mui/material";

export const DiscountCard = ({ discount }: { discount: Discount }) => {

    const defaultDiscountText = `PROMO - $${discount.value} Discount will be applied`;
    const recurrenceDiscountText = `PROMO - $${discount.value} Discount will be applied to each recurring appointment`;
    const discountText = discount.type === DiscountType.global_recurrence ? recurrenceDiscountText : defaultDiscountText;
    return (
        <Box
            width={{ xs: "100%" }}
            height={"36px"}
            bgcolor={"#FBEDFF"}
            borderRadius={"10px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            margin={"10px 0px"}
        >
            <Typography
                style={{ fontSize: 12 }}
                textAlign="center"
                fontFamily="Plain Bold"
                color="#CB29F3"
                fontWeight="bold">
                {discountText}
            </Typography>
        </Box>
    );
};