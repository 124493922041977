import { useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import HoldAppointment from "./temporaryAppointments/HoldAppointment";
import OnlineBookingContainer from "./onlineBooking/returning/OnlineBookingContainer";
import OnlineBookingLeadContainer from "./onlineBooking/newLead/OnlineBookingLeadContainer";
import { useOnlineBookingContext } from "./onlineBooking/OnlineBookingContext";
import { CustomStepSegment } from "@marathon/common/utilities/OnlineBookingSteps";
import { AdsClickIdType } from "@marathon/common/entities/AdsActivity";
import NotFound from "@marathon/web/components/NotFound";
import BookingSuggestionContainer from "./onlineBooking/BookingSuggestionContainer";
import Unleashed from "./unleashed/Unleashed";
import { UtmParameter } from "@marathon/common/entities/Customer";

const SMS_QUERY_KEY = "s";
const GCLID_QUERY_KEY = "gclid";
const MSCLKID_QUERY_KEY = "msclkid";
const FBCLID_QUERY_KEY = "fbclid";
const GBRAID_QUERY_KEY = "gbraid";
const WBRAID_QUERY_KEY = "wbraid";
const PHONE_QUERY_KEY = "phone";
const HIDE_PHONE_QUERY_VALUE = "blank";
const UTM_PARAM_KEY_PREFIX = "utm_";

export default function Navigations() {
  const { search } = useLocation();
  const { setIsFromSms, setClickId, setUtmParams, setHidePhoneNumber } = useOnlineBookingContext();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const utmParams: UtmParameter[] = Array.from(query.entries())
      .filter(([key]) => key.startsWith(UTM_PARAM_KEY_PREFIX))
      .map(([key, value]) => ({ key: key.replace(UTM_PARAM_KEY_PREFIX, ""), value }));

    setUtmParams(utmParams);
    setIsFromSms(query.has(SMS_QUERY_KEY));
    setHidePhoneNumber(query.has(PHONE_QUERY_KEY) && query.get(PHONE_QUERY_KEY) === HIDE_PHONE_QUERY_VALUE);
    getClickId(query);
    //HACK: We only want this useEffect to be triggered once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClickId = (query: URLSearchParams) => {
    const gclid = query.get(GCLID_QUERY_KEY);
    if (gclid) setClickId({ value: gclid, type: AdsClickIdType.gclid });

    const msclkid = query.get(MSCLKID_QUERY_KEY);
    if (msclkid) setClickId({ value: msclkid, type: AdsClickIdType.msclkid });

    const fbclid = query.get(FBCLID_QUERY_KEY);
    if (fbclid) setClickId({ value: fbclid, type: AdsClickIdType.fbclid });

    const gbraid = query.get(GBRAID_QUERY_KEY);
    if (gbraid) setClickId({ value: gbraid, type: AdsClickIdType.gbraid });

    const wbraid = query.get(WBRAID_QUERY_KEY);
    if (wbraid) setClickId({ value: wbraid, type: AdsClickIdType.wbraid });
  };

  return (
    <Switch>
      <Route exact path="/"><Redirect to="/online-booking" /></Route>
      <Route exact path="/appointment/:id" render={() => <HoldAppointment />} />
      <Route exact path="/booking-suggestion/:stepNumber?" render={() => <BookingSuggestionContainer />} />
      <Route exact path="/unleashed/:year" render={() => <Unleashed />} />
      <Route exact path={`/online-booking/${CustomStepSegment.manageAccount}`} render={() => <OnlineBookingContainer customStep={CustomStepSegment.manageAccount} />} />
      <Route exact path={`/online-booking/${CustomStepSegment.referral}`} render={() => <OnlineBookingContainer customStep={CustomStepSegment.referral} />} />
      <Route exact path={`/online-booking/${CustomStepSegment.notificationSettings}`} render={() => <OnlineBookingContainer customStep={CustomStepSegment.notificationSettings} />} />
      <Route exact path={`/online-booking/${CustomStepSegment.addCard}`} render={() => <OnlineBookingContainer customStep={CustomStepSegment.addCard} />} />
      <Route exact path="/online-booking-signup/:stepNumber?/:substepNumber?" render={() => <OnlineBookingLeadContainer />} />
      <Route exact path="/online-booking/:stepNumber?/:substepNumber?" render={() => <OnlineBookingContainer />} />
      <Route><NotFound /></Route>
    </Switch>
  );
}
