import { Gender, Groomer, GroomerData, GroomerRestrictionData, GroomerScheduleData, GroomerStatus } from "@marathon/common/entities/Groomer";

interface GroomerInput {
    firstname: string,
    lastname: string,
    email: string,
    phone: string,
    legacy_phone?: string,
    photo?: string,
    hub_id?: string,
    status: GroomerStatus,
    schedule: GroomerScheduleData[],
    available_online?: boolean,
    only_performs_bath?: boolean,
    allow_manual_booking?: boolean,
    start_date?: Date,
    end_date?: Date,
    gender?: Gender,
    restrictions?: GroomerRestrictionData,
    notes?: string,
    rippling_id?: string,
}

export function getGroomerInput(groomer: Groomer | null): GroomerInput {
    if (!groomer) {
        return {
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            status: GroomerStatus.active,
            schedule: []
        };
    }
    else {
        return {
            firstname: groomer.firstname,
            lastname: groomer.lastname,
            email: groomer.email,
            phone: groomer.phone,
            legacy_phone: groomer.legacy_phone,
            photo: groomer.photo,
            hub_id: groomer.hub_id,
            status: groomer.status,
            schedule: groomer.schedule,
            available_online: groomer.available_online,
            only_performs_bath: groomer.only_performs_bath,
            allow_manual_booking: groomer.allow_manual_booking,
            start_date: groomer.start_date,
            end_date: groomer.end_date,
            gender: groomer.gender,
            restrictions: groomer.restrictions,
            notes: groomer.notes,
            rippling_id: groomer.rippling_id
        };
    }
}

export function toGroomerData(input: GroomerInput) {
    if (!input.hub_id)
        throw new Error("Hub selection is required at this point");

    return {
        firstname: input.firstname,
        lastname: input.lastname,
        email: input.email,
        phone: input.phone,
        legacy_phone: input.legacy_phone,
        photo: input.photo,
        hub_id: input.hub_id,
        status: input.status,
        schedule: input.schedule,
        available_online: input.available_online,
        only_performs_bath: input.only_performs_bath,
        allow_manual_booking: input.allow_manual_booking,
        start_date: input.start_date,
        end_date: input.end_date,
        gender: input.gender,
        restrictions: input.restrictions,
        notes: input.notes,
        rippling_id: input.rippling_id
    } as GroomerData;
}

export type { GroomerInput };
