import { Customer } from "./Customer";
import { Hub } from "./Hub";

export const DEFAULT_PRESET_NAME = "Los Angeles";

interface ServicePriceData {
    service_id: string,
    price: number
}

interface PricingPresetData {
    name: string,
    services: ServicePriceData[]
}

interface PricingPreset extends PricingPresetData {
    id: string
}

class PricingPreset {
    constructor(id: string, data: PricingPresetData) {
        this.id = id;
        Object.assign(this, data);
    }
    toData(): PricingPresetData {
        const { id, ...data } = this;
        return data;
    }
    static getForCustomer(customer: Customer | null, pricingPresets: PricingPreset[], hubs: Hub[]) {
        return PricingPreset.getForHubId(pricingPresets, hubs, customer?.address.drive_time?.hub_id);
    }
    static getForHubId(pricingPresets: PricingPreset[], hubs: Hub[], hubId?: string) {
        const defaultPricingPreset = pricingPresets.find(x => x.name === DEFAULT_PRESET_NAME);
        if (!defaultPricingPreset)
            throw new Error("Default pricing preset not found");

        const hub = hubs.find(x => x.id === hubId);
        return pricingPresets.find(x => x.id === hub?.pricing_preset_id) ?? defaultPricingPreset;
    }
}

export { PricingPreset };
export type { PricingPresetData, ServicePriceData };