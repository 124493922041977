import { Groomer, GroomerData, GroomerStatus } from "@marathon/common/entities/Groomer";
import { AppointmentRepository } from "./AppointmentRepository";
import { RecurrenceRepository } from "./RecurrenceRepository";
import { getFutureOccurrences, getOccurrenceFromIndex } from "@marathon/common/helpers/rruleHelper";
import { AppointmentOrOccurrence } from "@marathon/common/entities/Occurrence";
import { DailyAnnouncementData } from "@marathon/common/entities/DailyAnnouncement";
import { DailyAnnouncementRepository } from "./DailyAnnouncementRepository";
import { normalizePhone } from "@marathon/common/helpers/normalizationHelper";
import LocalDate from "@marathon/common/utilities/LocalDate";
import DeviceStorageCache from "@marathon/client-side/utilities/DeviceStorageCache";
import { Appointment, AppointmentData, AppointmentStatus } from "@marathon/common/entities/Appointment";
import { DocResult, INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { IFirestoreService } from "./IFirestoreService";
import { container, inject, singleton } from "tsyringe";
import { FilterOperator, getFilter, getOrderBy, OrderDirection, QueryFilter } from "@marathon/common/api/QueryFilter";
import { deleteFieldInternal } from "@marathon/common/utilities/TypeUtils";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";
import { CustomerMessageRepository } from "./CustomerMessageRepository";
import { GroomerMessageRepository } from "./GroomerMessageRepository";
import { GroomerLogRepository } from "./GroomerLogRepository";
import { GroomerLogActivities } from "@marathon/common/entities/GroomerLog";
import { GroomerInput, toGroomerData } from "@marathon/client-side/entities/GroomerInput";
import { UserRole } from "@marathon/common/entities/User";
import { Hub } from "@marathon/common/entities/Hub";

const mapEntity = function (snapshot: DocResult<GroomerData>) {
    return new Groomer(snapshot.id, snapshot.data);
};

const normalizeData = (data: Partial<GroomerData>) => {
    data.firstname = data.firstname?.trim();
    data.lastname = data.lastname?.trim();
    data.phone = data.phone ? normalizePhone(data.phone) : "";
    data.legacy_phone = data.legacy_phone ? normalizePhone(data.legacy_phone) : "";
};

@singleton()
export class GroomerRepository {
    private firestoreService: IFirestoreService<GroomerData>;
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<GroomerData>) {
        injectedService.collectionPath = CollectionPaths.Groomers;
        this.firestoreService = injectedService;
    }
    static get current() {
        return container.resolve(GroomerRepository);
    }
    async getById(id: string) {
        const doc = await this.firestoreService.getById(id);
        return doc ? mapEntity(doc) : null;
    }
    async getByUid(uid: string) {
        const result = await this.firestoreService.search({
            filters: [getFilter("uid", FilterOperator.equal, uid)]
        });
        return result.length ? mapEntity(result[0]) : null;
    }
    async search(isFromCalendar = false) {
        const results = await this.firestoreService.search({
            filters: [getFilter("status", FilterOperator.equal, GroomerStatus.active)],
            orders: [getOrderBy("hub_id", OrderDirection.asc), getOrderBy("firstname", OrderDirection.asc)]
        });
        //HACK: This is a temporary fix to prevent the App Store reviewer groomer from appearing in the calendar.
        const filteredResults = isFromCalendar
            ? results.filter(x => !x.data.is_store_reviewer)
            : results;
        return filteredResults.map(mapEntity);
    }
    async searchAll() {
        const docs = await this.firestoreService.search({
            filters: [getFilter("status", FilterOperator.in, [GroomerStatus.active, GroomerStatus.inactive])],
            orders: [getOrderBy("hub_id", OrderDirection.asc), getOrderBy("firstname", OrderDirection.asc)]
        });
        return docs.map(mapEntity);
    }
    async searchByHub(hubId: string) {
        const docs = await this.firestoreService.search({
            filters: [
                getFilter("hub_id", FilterOperator.equal, hubId),
                getFilter("status", FilterOperator.equal, GroomerStatus.active)
            ],
        });
        return docs.map(mapEntity);
    }
    async searchAvailableOnline() {
        const groomers = await this.search();
        return groomers.filter(g => g.available_online);
    }
    async createFromInput(uid: string, hub: Hub, groomerInput: GroomerInput) {
        const toCreate = toGroomerData(groomerInput);
        normalizeData(toCreate);
        const groomerData = {
            ...toCreate,
            uid: uid,
            role: UserRole.groomer,
            hub_id: hub.id,
            time_zone: hub.time_zone,
            created_at: new Date(),
            open_conversation: false
        };
        return await this.firestoreService.create(groomerData);
    }
    async updateFromInput(id: string, hub: Hub, groomerInput: GroomerInput) {
        const toUpdate = toGroomerData(groomerInput);
        normalizeData(toUpdate);
        await this.firestoreService.update(id, {
            ...toUpdate,
            start_date: toUpdate.start_date ?? deleteFieldInternal,
            end_date: toUpdate.end_date ?? deleteFieldInternal,
            hub_id: hub.id,
            time_zone: hub.time_zone,
            updated_at: new Date()
        });
    }
    async remove(id: string) {
        await this.firestoreService.update(id, { status: GroomerStatus.deleted });
    }
    async sendDailyAnnouncement(groomer: Groomer) {
        const sendDate = LocalDate.forContextTimeZone(new Date(), groomer.time_zone);

        const getMessageContent = (appointment: AppointmentOrOccurrence) => {
            let message = `Hi ${appointment.customer.name.split(" ")[0]}! This is Barkbus Mobile Grooming. `;
            message += appointment.show_exact_time
                ? `We look forward to your ${Appointment.getArrivalTimeWithLabel(appointment, { includePreposition: false })} appointment. See you soon!`
                : `We look forward to your appointment later today with a ${Appointment.getArrivalTimeWithLabel(appointment, { includePreposition: false })} arrival window. We will text you when we’re on the way!`;
            return message;
        };

        const existingAnnouncements = await DailyAnnouncementRepository.current.search(sendDate, groomer.id);
        if (existingAnnouncements.length)
            throw new Error("An announcement was already sent for the day");

        const allAppointments = await this.searchScheduledAppointmentsAndOccurrencesForDate(groomer.id, sendDate);
        const announcement: DailyAnnouncementData = {
            sent_date: sendDate.toDayStart(),
            time_zone: groomer.time_zone,
            messages: []
        };

        for (const appointment of allAppointments) {
            const message = await CustomerMessageRepository.current.createSms({
                parentId: appointment.customer.id,
                content: getMessageContent(appointment)
            });

            announcement.messages.push({
                customerId: appointment.customer.id,
                messageId: message.id
            });
        }

        await DailyAnnouncementRepository.current.create(groomer.id, announcement);
    }
    private listenByLastMessages(queries: QueryFilter<GroomerData>[], callback: (data: Groomer[]) => void) {
        const constraints: QueryFilter<GroomerData>[] = [
            getFilter("status", FilterOperator.equal, GroomerStatus.active),
            ...queries
        ];
        return this.firestoreService.onQuerySnapshot({
            filters: constraints
        }, docs => {
            const groomers = docs
                .map(mapEntity)
                .sortByFieldDescending(x => x.last_message_at?.getTime() ?? 0);
            callback(groomers);
        });
    }
    listenAll(callback: (data: Groomer[]) => void) {
        const constraints: QueryFilter<GroomerData>[] = [
            getFilter("status", FilterOperator.in, [GroomerStatus.active, GroomerStatus.inactive])
        ];
        return this.firestoreService.onQuerySnapshot({
            filters: constraints,
        }, docs => {
            const groomers = docs.map(mapEntity);
            callback(groomers);
        });
    }
    listenByLastMessagesOpen(callback: (data: Groomer[]) => void) {
        const constraints: QueryFilter<GroomerData>[] = [
            getFilter("open_conversation", FilterOperator.equal, true)
        ];
        return this.listenByLastMessages(constraints, callback);
    }
    async toggleOpenConversation(groomer: Groomer) {
        if (groomer.open_conversation) {
            await this.closeConversation(groomer);
        }
        else {
            await this.firestoreService.update(groomer.id, {
                open_conversation: true,
                updated_by: DeviceStorageCache.getCurrentUserName() ?? undefined
            });
        }
    }
    async closeConversation(groomer: Groomer) {
        this.checkAndDisableChatbot(groomer);
        await this.firestoreService.update(groomer.id, {
            open_conversation: false,
            updated_by: DeviceStorageCache.getCurrentUserName() ?? undefined
        });
    }
    async markNewMessage(id: string, status = false) {
        await this.firestoreService.update(id, { new_message: status });
    }
    listenChanges(id: string, callback: (data: Groomer) => void) {
        return this.firestoreService.onDocumentSnapshot(id, snapshot => {
            if (!snapshot) return;
            callback(mapEntity(snapshot));
        });
    }
    async createNote(id: string, note: string) {
        await this.firestoreService.update(id, { notes: note });
    }
    async searchAllWithCurrentChatbot() {
        const filters: QueryFilter<GroomerData>[] = [
            getFilter("current_chatbot_activity_id", FilterOperator.notEqual, "")
        ];
        const docs = await this.firestoreService.search({ filters });
        return docs.map(x => mapEntity(x));
    }
    async searchUpcomingAppointmentOrOccurrence(groomerId: string) {
        const appointments = await AppointmentRepository.current.searchUpcomingByGroomer(groomerId, new Date, 1);
        const recurrences = await RecurrenceRepository.current.searchForGroomer(groomerId);
        const futureOccurrences = recurrences.map(x => getFutureOccurrences(x, 1)).flat();
        const all = [
            ...appointments,
            ...futureOccurrences
        ];
        if (!all || all.length === 0)
            return null;

        return all.sortByFieldAscending(x => x.start_time)[0];
    }
    async removeLiveAgentRequestedMark(groomerId: string) {
        await this.firestoreService.update(groomerId, { live_agent_requested: false });
    }
    async checkAndDisableChatbot(groomer: Groomer) {
        await this.removeLiveAgentRequestedMark(groomer.id);
        if (groomer.current_chatbot_activity_id) {
            await this.firestoreService.update(groomer.id, { current_chatbot_activity_id: deleteFieldInternal });
            await GroomerLogRepository.current.create(groomer.id, {
                description: GroomerLogActivities.chatbotTurnedOff,
                date: new Date(),
                user: DeviceStorageCache.getNonEmptyCurrentUserName()
            });
        }
    }
    async searchScheduledAppointmentsAndOccurrencesForDate(groomerId: string, localDate: LocalDate) {
        const appointments = await AppointmentRepository.current.searchForDayAndGroomer(localDate, localDate, groomerId);
        const occurrences = await RecurrenceRepository.current.searchOccurrencesForDayAndGroomer(localDate, groomerId);
        const allAppointments: AppointmentOrOccurrence[] = [];
        allAppointments.push(...appointments);
        allAppointments.push(...occurrences);
        const filteredList = allAppointments.filter(x => x.status === AppointmentStatus.scheduled);
        filteredList.sortByFieldAscending(x => x.start_time);
        return filteredList;
    }
    async notifyAppointmentCancellationFromId(id: string, occurrenceIndex?: number) {
        if (occurrenceIndex !== undefined) {
            const recurrence = await RecurrenceRepository.current.getById(id);
            if (!recurrence) throw new Error(`Recurrence ${id} not found`);
            const occurrence = getOccurrenceFromIndex(recurrence, occurrenceIndex);
            await this.notifyAppointmentCancellation(occurrence);
        }
        else {
            const appointment = await AppointmentRepository.current.getById(id);
            if (!appointment) throw new Error(`Appointment ${id} not found`);
            await this.notifyAppointmentCancellation(appointment);
        }
    }
    async notifyAppointmentCancellation(appointment: AppointmentData) {
        if (!Appointment.isToday(appointment.start_time, appointment.time_zone))
            return;

        const groomer = await this.getById(appointment.groomer.id);
        if (!groomer)
            throw new Error(`Groomer ${appointment.groomer.id} not found`);

        const message = `Notification - An appointment was canceled today:
          Customer: ${appointment.customer.name}\n Number of dogs: ${appointment.selected_pets.length > 1
                ? `${appointment.selected_pets.length} dogs`
                : "1 dog"}
          Arrival window:  ${Appointment.getArrivalTimeWithLabel(appointment)}`;

        await GroomerMessageRepository.current.createSms({
            parentId: groomer.id,
            content: message
        });
    }
    async notifyAppointmentChange(appointmentId: string) {
        const appointment = await AppointmentRepository.current.getById(appointmentId);
        if (!appointment)
            throw new Error(`Appointment ${appointmentId} not found`);

        const groomer = await this.getById(appointment.groomer.id);
        if (!groomer)
            throw new Error(`Groomer ${appointment.groomer.id} not found`);

        const message = `Notification - An appointment for today has been modified:
         Customer:${appointment.customer.name}
         Number of dogs: ${appointment.selected_pets.length > 1
                ? `${appointment.selected_pets.length} dogs`
                : "1 dog"}
         Arrival window:  ${Appointment.getArrivalTimeWithLabel(appointment)}`;

        await GroomerMessageRepository.current.createSms({
            parentId: groomer.id,
            content: message
        });
    }
    async updatePushNotificationToken(id: string, token: string) {
        await this.firestoreService.update(id, { push_notification_token: token });
    }
}
