import { DateTime } from "luxon";
import { TimeZone } from "@marathon/common/helpers/timeZoneHelper";
import { formatTime24 } from "@marathon/common/helpers/timeFormatHelper";
import { GroomerException } from "./GroomerException";
import LocalDate from "../utilities/LocalDate";
import { VanException } from "./VanException";
import { Van } from "./Van";
import { shouldBeUnreachable } from "../helpers/typesHelper";
import { Customer, CustomerAddressData } from "./Customer";
import { SecondaryAddress } from "./SecondaryAddress";
import { UserRole } from "./User";

export type DayOfWeek = "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday";
export const ZERO_BASED_DAYS_OF_WEEK = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"] as DayOfWeek[];
export const DAYS_OF_WEEK = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"] as DayOfWeek[];

export const EVENT_DETAILS_SCREEN_NAME = "Appointment Details";
export const EVENT_MESSAGES_SCREEN_NAME = "Messages";
export const EVENT_CALL_SCREEN_NAME = "Call Customer";
export const GROOMER_EVENT_LINKING_SEGMENT = "groomer-event";

enum GroomerStatus {
    active = "active",
    inactive = "inactive",
    deleted = "deleted"
}

enum Gender {
    male = "male",
    female = "female",
    nonBinary = "non-binary"
}

interface GroomerScheduleData {
    day: DayOfWeek,
    from: string,
    to: string,
    cut_off?: boolean,
}

interface GroomerRestrictionData {
    service_ids: string[]
}

interface AssignedVan {
    van?: Van,
    exception_id?: string
}

interface GroomerData {
    firstname: string,
    lastname: string,
    phone: string,
    legacy_phone?: string,
    email: string,
    hub_id: string,
    photo?: string,
    notes?: string,
    role: UserRole,
    uid: string,
    air_call?: {
        contact_id: number,
        phone_id: number
    },
    created_at: Date,
    updated_by?: string,
    updated_at?: Date,
    open_conversation: boolean,
    schedule: GroomerScheduleData[],
    status: GroomerStatus,
    available_online?: boolean,
    only_performs_bath?: boolean,
    allow_manual_booking?: boolean,
    start_date?: Date,
    end_date?: Date,
    restrictions?: GroomerRestrictionData
    time_zone: TimeZone
    new_message?: boolean
    last_message_at?: Date,
    gender?: Gender,
    push_notification_token?: string,
    current_chatbot_activity_id?: string
    live_agent_requested?: true,
    is_store_reviewer?: true,
    salesforce_resource_id?: string,
    rippling_id?: string
}

interface Groomer extends GroomerData {
    id: string
}

interface GroomerSummary {
    id: string,
    firstname: string,
    lastname: string,
    fullname: string,
    obfuscatedName: string,
    hub_id: string,
    phone: string,
    email: string
}

class Groomer {
    constructor(id: string, data: GroomerData) {
        this.id = id;
        Object.assign(this, data);
    }
    fullname(): string {
        return this.firstname + " " + this.lastname;
    }
    obfuscatedName(): string {
        return this.firstname + " " + this.lastname.charAt(0);
    }
    toData(): GroomerData {
        const { id, ...data } = this;
        return data;
    }
    toSummary(): GroomerSummary {
        return {
            id: this.id,
            firstname: this.firstname,
            lastname: this.lastname,
            fullname: this.fullname(),
            obfuscatedName: this.obfuscatedName(),
            hub_id: this.hub_id,
            phone: this.phone,
            email: this.email
        };
    }
    getSummaryForAppointment() {
        return {
            id: this.id,
            name: this.fullname(),
            phone: this.phone,
            email: this.email,
            hub_id: this.hub_id
        };
    }
    hasRestrictions(serviceIds: string[]) {
        if (!this.restrictions) {
            return false;
        }
        return this.restrictions.service_ids.some(x => serviceIds.includes(x));
    }
    hasGenderRestriction(groomerGender?: Gender) {
        if (!groomerGender)
            return false;

        return this.gender !== groomerGender;
    }
    getScheduleForDay(date: LocalDate, groomerExceptions: GroomerException[]) {
        const exception = groomerExceptions.find(x => x.groomer_id === this.id && x.belongsTo(date));
        if (exception) {
            if (!exception.is_available)
                return undefined;

            return {
                day: ZERO_BASED_DAYS_OF_WEEK[date.getZeroBasedWeekDay()],
                from: formatTime24(exception.from, this.time_zone),
                to: formatTime24(exception.to, this.time_zone)
            } as GroomerScheduleData;
        }

        if (this.start_date && date.isLessThanDate(this.start_date, this.time_zone))
            return undefined;

        if (this.end_date && date.isGreaterThanDate(this.end_date, this.time_zone))
            return undefined;

        return this.schedule.find(x =>
            ZERO_BASED_DAYS_OF_WEEK[date.getZeroBasedWeekDay()] === x.day
        );
    }
    getWorkingHoursForDay(date: LocalDate, groomerExceptions: GroomerException[]) {
        const availability = this.getScheduleForDay(date, groomerExceptions);
        if (!availability) {
            return 0;
        }
        const groomerStart = date.toDateWithParsedTime(availability.from);
        const groomerEnd = date.toDateWithParsedTime(availability.to);
        const difference = DateTime.fromJSDate(groomerEnd).diff(DateTime.fromJSDate(groomerStart), "hours");
        return difference.hours;
    }
    isWithinAvailability(groomerExceptions: GroomerException[], startTime: Date, endTime: Date) {
        const localDate = LocalDate.forContextTimeZone(startTime, this.time_zone);
        const availability = this.getScheduleForDay(localDate, groomerExceptions);
        if (availability && availability.from && availability.to) {
            const groomerStart = localDate.toDateWithParsedTime(availability.from);
            const groomerEnd = localDate.toDateWithParsedTime(availability.to);
            return (
                startTime >= groomerStart &&
                endTime <= groomerEnd
            );
        }
        else {
            return false;
        }
    }
    static getAssignedVan(vans: Van[], date: LocalDate, groomerId: string, vanException?: VanException): AssignedVan {
        if (vanException) {
            const van = vans.find(x => x.id === vanException.van_id);
            return {
                van,
                exception_id: vanException.id,
            };
        }
        else {
            const van = vans.find(x => x.isAssignedToGroomer(groomerId, date.getDayOfWeek()));
            return {
                van,
                exception_id: undefined,
            };
        }
    }
    getAssignedVan(vans: Van[], date: LocalDate, vanException?: VanException): AssignedVan {
        return Groomer.getAssignedVan(vans, date, this.id, vanException);
    }
    static getChangesInformation(before: GroomerData, after: GroomerData) {
        const hubIdChanged = after.hub_id && before.hub_id !== after.hub_id;
        const phoneChanged = after.phone && before.phone !== after.phone;
        const emailChanged = after.email && before.email !== after.email;

        const nameChanged =
            after.firstname && after.lastname &&
            (before.firstname !== after.firstname || before.lastname !== after.lastname);

        const statusChangedToInactive = after.status !== GroomerStatus.active && before.status === GroomerStatus.active;

        const hasChangedRestrictions =
            before.restrictions?.service_ids.length !== after.restrictions?.service_ids.length ||
            before.restrictions?.service_ids.some((serviceId, index) => serviceId !== after.restrictions?.service_ids[index]);

        return {
            hubIdChanged,
            phoneChanged,
            emailChanged,
            nameChanged,
            statusChangedToInactive,
            hasChangedRestrictions
        };
    }
    static relativeDetailsPageUrl(id: string) {
        return `/groomers/${id}`;
    }
    static wasUpdatedByGroomer(after: GroomerData) {
        return after.updated_by === UserRole.groomer && after.new_message;
    }
    static isOpeningConversation(before: GroomerData, after: GroomerData) {
        return (
            !before?.open_conversation &&
            after?.open_conversation &&
            Groomer.wasUpdatedByGroomer(after)
        );
    }
    static isDayOffForLocalDate(groomer: Groomer, date: LocalDate, groomerExceptions: GroomerException[]) {
        const exception = groomerExceptions.find(x => x.groomer_id === groomer.id && x.belongsTo(date));
        if (exception)
            return !exception.is_available;

        if (groomer.start_date && date.isLessThanDate(groomer.start_date, groomer.time_zone))
            return true;

        if (groomer.end_date && date.isGreaterThanDate(groomer.end_date, groomer.time_zone))
            return true;

        const workingDays = groomer.schedule.map(x => x.day);
        return ZERO_BASED_DAYS_OF_WEEK
            .filter(weekDay => !workingDays.includes(weekDay))
            .some(weekDay => ZERO_BASED_DAYS_OF_WEEK.findIndex(x => x === weekDay) === date.getZeroBasedWeekDay());
    }
    static isDayOff(groomer: Groomer, date: Date, groomerExceptions: GroomerException[]) {
        const localDate = LocalDate.forContextTimeZone(date, groomer.time_zone);
        return this.isDayOffForLocalDate(groomer, localDate, groomerExceptions);
    }
    static isTestGroomer(email: string) {
        const regExp = /\S+@test.\S+/;
        return regExp.test(email);
    }
    static filterForBookingSuggestions(
        groomers: Groomer[],
        customer: Customer,
        selectedAddress: CustomerAddressData | SecondaryAddress | undefined,
        serviceIds: string[]
    ) {
        return (
            groomers.filter(groomer =>
                groomer.hub_id === selectedAddress?.drive_time?.hub_id &&
                !groomer.hasRestrictions(serviceIds) &&
                !customer.exclude_groomer_ids?.includes(groomer.id)
            )
        );
    }
    relativeDetailsPageUrl() {
        return `/groomers/${this.id}`;
    }
    genderPronoun() {
        if (!this.gender)
            return null;
        else {
            switch (this.gender) {
                case Gender.female:
                    return "She/Her";
                case Gender.male:
                    return "He/Him";
                case Gender.nonBinary:
                    return "They/Them";
                default:
                    shouldBeUnreachable(this.gender);
            }
        }
    }
    static get defaultTimeInput() {
        return {
            from: "06:00",
            to: "22:00"
        };
    }
    static get calendarTimeEdge() {
        return {
            startString: "06:00",
            startNumber: 6,
            endString: "23:00",
            endNumber: 23
        };
    }
}

export { Groomer, GroomerStatus, Gender };
export type { GroomerData, GroomerScheduleData, GroomerRestrictionData, GroomerSummary, AssignedVan };