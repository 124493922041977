import { DiscountCode, DiscountCodeData, DiscountCodeStatus } from "@marathon/common/entities/DiscountCode";
import { DocResult, INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { IFirestoreService } from "./IFirestoreService";
import { container, inject, singleton } from "tsyringe";
import { FilterOperator, getFilter } from "@marathon/common/api/QueryFilter";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";
import { UpdateDataInternal } from "@marathon/common/utilities/TypeUtils";
import { Discount, DiscountType } from "@marathon/common/entities/Discount";
import { Customer } from "@marathon/common/entities/Customer";
import { UsedDiscountRepository } from "./UsedDiscountRepository";

const mapEntity = function (snapshot: DocResult<DiscountCodeData>) {
    return new DiscountCode(snapshot.id, snapshot.data);
};

@singleton()
export class DiscountCodeRepository {
    private firestoreService: IFirestoreService<DiscountCodeData>;
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<DiscountCodeData>) {
        injectedService.collectionPath = CollectionPaths.DiscountCodes;
        this.firestoreService = injectedService;
    }
    static get current() {
        return container.resolve(DiscountCodeRepository);
    }
    async search(types: DiscountType[]) {
        if (types.length === 0)
            return [];
        const docs = await this.firestoreService.search({
            filters: [getFilter("type", FilterOperator.in, [...types])]
        });
        return docs.map(x => mapEntity(x));
    }
    async update(id: string, data: UpdateDataInternal<DiscountCodeData>) {
        await this.firestoreService.update(id, data);
    }
    async markAsUsed(id: string) {
        await DiscountCodeRepository.current.update(id, { status: DiscountCodeStatus.used });
    }
    async createWithRandomCode(partial: Omit<DiscountCodeData, "code">) {
        const data: DiscountCodeData = {
            ...partial,
            code: DiscountCode.generateRandomCode()
        };
        const docId = await this.firestoreService.create(data);
        return mapEntity({ id: docId, data });
    }
    async getByCode(code: string) {
        const docs = await this.firestoreService.search({
            filters: [
                getFilter("code", FilterOperator.equal, code)
            ]
        });
        const discountCode = docs.map(x => mapEntity(x)).at(0);
        return discountCode?.canBeUsed() ? discountCode : null;
    }
    async validateForCustomer(discountCode: DiscountCode, customer: Customer) {
        if (Discount.types.global.includes(discountCode.type)) {
            const hasBeenUsed = await UsedDiscountRepository.current.hasBeenUsed(customer.id, discountCode.code);
            return (
                discountCode.isValidGlobalCode(customer) && !hasBeenUsed
            );
        }
        else {
            return discountCode.canBeUsed();
        }
    }
}
