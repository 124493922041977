import { Box, Button as DefaultButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
    showSpinner?: boolean,
    disabled?: boolean,
    children: string | React.ReactNode,
    iconName?: string,
    iconOnLeftEdge?: true,
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning",
    variant?: "contained" | "text" | "outlined",
    [x: string]: unknown
}

export const Button = ({ showSpinner = false, disabled = false, children, iconName = undefined, iconOnLeftEdge = undefined, color = "primary", variant = "text", ...rest }: Props) => {
    const startIcon = iconName && iconOnLeftEdge && !showSpinner
        ? <i className="material-icons">{iconName}</i>
        : undefined;
    const endIcon = iconName && !iconOnLeftEdge && !showSpinner
        ? <i className="material-icons">{iconName}</i>
        : undefined;
    return (
        <DefaultButton
            disabled={disabled || showSpinner}
            color={color}
            variant={variant}
            sx={{
                position: "relative",
                textAlign: "center"
            }}
            {...rest}
        >
            {startIcon &&
                <Box
                    component="span"
                    sx={{
                        position: "absolute",
                        left: 10,
                        top: "50%",
                        transform: "translateY(-50%)",
                        display: "inline-flex",
                        alignItems: "center"
                    }}
                >
                    {startIcon}
                </Box>}
            {showSpinner && <CircularProgress color="inherit" size={20} />}
            {!showSpinner && children}
            {endIcon &&
                <Box
                    component="span"
                    sx={{
                        position: "absolute",
                        right: 10,
                        top: "50%",
                        transform: "translateY(-50%)",
                        display: "inline-flex",
                        alignItems: "center"
                    }}
                >
                    {endIcon}
                </Box>}
        </DefaultButton>
    );
};