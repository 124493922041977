export default class ArrayGroups {
    static getBy<T, K>(array: T[], keyGetter: (item: T) => K): Map<K, T[]> {
        const map = new Map<K, T[]>();

        array.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);

            if (collection) {
                collection.push(item);
            } else {
                map.set(key, [item]);
            }
        });

        return map;
    }
    static chunk<T>(array: T[], size: number): T[][] {
        return Array.from({ length: Math.ceil(array.length / size) }, (_v, i) =>
            array.slice(i * size, i * size + size)
        );
    }
}